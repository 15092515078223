import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import Papa from 'papaparse';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, LineElement, TimeScale } from 'chart.js';
import { FaArrowRight, FaBridge, FaTrash } from "react-icons/fa6";
import { FaArrowCircleRight, FaEdit } from "react-icons/fa";
import { MdSettings, MdPerson, MdSearch, MdNotifications, MdDashboard, MdSensors, MdDescription, MdLogout, MdEdit } from 'react-icons/md';
import { PiWind } from "react-icons/pi";
import { WiHumidity } from "react-icons/wi";
import { GiSpeedometer } from "react-icons/gi";
import MapView from "@arcgis/core/views/MapView"
import Map from "@arcgis/core/Map"
import "@arcgis/core/assets/esri/themes/light/main.css";
import '@arcgis/core/assets/esri/css/main.css';
import './tailwind.css';
import 'react-phone-input-2/lib/style.css';
import '../Pages/tailwind.css';

import logo2 from '../Assets/logo2.png';
import clear_icon from '../Assets/weather/clear.png';
import cloud_icon from '../Assets/weather/cloud.png';
import drizzle_icon from '../Assets/weather/drizzle.png';
import rain_icon from '../Assets/weather/rain.png';
import snow_icon from '../Assets/weather/snow.png';
import mist_icon from '../Assets/weather/fog.png';
import thunderstorm_icon from '../Assets/weather/thunderstorm.png';
import './masterhome.css'
// import stdimg from '../Assets/stdimg.jpg';

import SensorData from '../Assets/Data.csv';
import accelerometer1 from '../Assets/accelerometer1.csv';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import GaugeChart from 'react-gauge-chart';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
// import 'jspdf-autotable'
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(zoomPlugin);

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend)



const Superuserhome = ({ id: superuserID }) => {
    const [chartData, setChartData] = useState({});
    const [chartData1, setChartData1] = useState({});
    const [chartData2, setChartData2] = useState({});
    const [chartData3, setChartData3] = useState({});
    const [chartData4, setChartData4] = useState({});
    const [chartData5, setChartData5] = useState({});
    const [chartData6, setChartData6] = useState({});
    const [chartData7, setChartData7] = useState({});

    const [averageLoggerTemp, setAverageLoggerTemp] = useState(null);
    const [averageBatteryVoltage, setAverageBatteryVoltage] = useState(null);
    //Sensor1
    const [averageSensor1Temp, setAverageSensor1Temp] = useState(null);
    const [averageSensor1Freq, setAverageSensor1Freq] = useState(null);
    const [averageSensor1Eng, setAverageSensor1Eng] = useState(null);
    //Sensor2
    const [averageSensor2Temp, setAverageSensor2Temp] = useState(null);
    const [averageSensor2Freq, setAverageSensor2Freq] = useState(null);
    const [averageSensor2Eng, setAverageSensor2Eng] = useState(null);
    // const [selectedAxis, setSelectedAxis] = useState('x');
    const [selectedAxis1, setSelectedAxis1] = useState('x');
    const [accelData1, setAccelData1] = useState([]);
    ;

    const BatteryGauge = ({ id }) => {
        const [selectedVoltage, setSelectedVoltage] = useState(18);

        const [range, setRange] = useState([3, 6, 9, 18]); // Custom range, replace arcsLength with range
        const [isEditOpen, setIsEditOpen] = useState(false); // State to toggle modal visibility
        const [newVoltage, setNewVoltage] = useState(18); // Temporary voltage for modal
        const [newRange, setNewRange] = useState(range); // Temporary range for modal

        // Function to handle voltage input change
        const handleVoltageChange = (e) => {
            setSelectedVoltage(Number(e.target.value));
        };


        // Fetch battery data from the API


        // Calculate percentage based on fetched battery voltage
        const handleSubmit = () => {
            setSelectedVoltage(newVoltage);
            setRange(newRange);
            setIsEditOpen(false); // Close the modal after submitting
        };

        return (
            <div style={{
                width: '400px',
                height: '350px', // Set a specific height
                padding: '20px',
                border: '2px solid black',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
            }}>

                {/* Display selected voltage */}
                <h3 style={{ fontWeight: 'bold' }}>Battery Set to: {selectedVoltage}V</h3>
                <div style={{ textAlign: 'center', width: '470px' }}>
                    {!isLoading ? (
                        <GaugeChart
                            id="battery-gauge"
                            nrOfLevels={range.length} // Number of levels based on custom range
                            arcsLength={range.map(value => value / selectedVoltage)} // Dynamic arcs based on user range
                            colors={['#FF0000', '#FFA500', '#FFD700', '#008000']} // Colors for the gauge
                            percent={batteryVoltage / selectedVoltage} // Adjust gauge percentage according to selectedVoltage
                            arcPadding={0.02}
                            textColor={'#000000'}
                            needleColor={'#000000'}
                            needleBaseColor={'#000000'}
                            style={{ height: '250px', width: '450px' }}
                            formatTextValue={() => `${batteryVoltage.toFixed(1)}V`} // Display the battery voltage
                        />
                    ) : (
                        <p>Loading battery data...</p>
                    )}
                </div>

                {/* Edit Button */}
                <button onClick={() => setIsEditOpen(true)} style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    padding: '5px 10px',
                    backgroundColor: '#007BFF',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>Edit</button>

                {/* Modal for voltage and range input */}
                {isEditOpen && (
                    <div style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#fff',
                        padding: '20px',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                        zIndex: 1000,
                        width: '300px' // Decreased width
                    }}>
                        <h4>Edit Voltage and Range</h4>

                        {/* Voltage Input */}
                        <label>Enter Voltage: </label>
                        <input
                            type="number"
                            value={newVoltage}
                            onChange={(e) => setNewVoltage(Number(e.target.value))}
                            style={{ marginBottom: '10px', width: '100%' }}
                        />

                        {/* Range Input */}
                        <label>Set Range (comma-separated values): </label>
                        <input
                            type="text"
                            value={newRange.join(', ')}
                            onChange={(e) => setNewRange(e.target.value.split(',').map(Number))}
                            style={{ marginBottom: '10px', width: '100%' }}
                        />

                        {/* Submit Button */}
                        <button onClick={handleSubmit} style={{
                            padding: '5px 10px',
                            backgroundColor: '#28A745',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}>Submit</button>

                        {/* Cancel Button */}
                        <button onClick={() => setIsEditOpen(false)} style={{
                            marginLeft: '10px',
                            padding: '5px 10px',
                            backgroundColor: '#DC3545',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}>Cancel</button>
                    </div>
                )}


                {/* Overlay for modal */}
                {isEditOpen && <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: 999
                }}></div>}
            </div>
        );
    };

    //humidity monitoring graph
    const [humidityData, setHumidityData] = useState([]);
    const humidityCsvFilePath = '/path/to/humidity-data.csv';

    useEffect(() => {
        loadedCsvData(humidityCsvFilePath, setHumidityData);
    }, [humidityCsvFilePath]);

    const humidityGraphData = {
        labels: humidityData.map(row => row['']), // X-axis as timestamp
        datasets: [
            // Add dataset configuration here
        ]
    };

    const humidityOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: '',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: '',
                }
            },
            y: {
                title: {
                    display: true,
                    text: '',
                }
            }
        }
    };

    // Pressure data
    const [pressureData, setPressureData] = useState([]);
    const [selectedPressureAxis, setSelectedPressureAxis] = useState('x');


    // Prepare data for the graph
    const pressureChartData = {
        labels: pressureData.map(row => row.timestamp.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })),  // X-axis will display time
        datasets: [
            {
                label: 'Pressure',
                data: pressureData.map(row => row.pressure),
                borderColor: 'rgba(255, 99, 132, 1)', // Line color
                fill: false,
                tension: 0.1,
            }
        ],
    };

    // Configure graph options
    const pressureChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'top' },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label || ''}: ${context.parsed.y}`
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x', // Enable horizontal panning
                    threshold: 10,
                    onPan: ({ chart }) => {
                        const { min, max } = chart.scales.x;
                        if (min <= chart.data.labels[0]) {
                            loadOlderData(); // Load older data if needed when panning
                        }
                    },
                },
                zoom: {
                    wheel: {
                        enabled: false,
                    },
                    drag: {
                        enabled: false,
                    },
                    mode: 'x',
                }
            }
        },
        scales: {
            x: {
                // No need to specify type if it's already properly registered

                title: { display: true, text: 'Time' },
                ticks: {
                    autoSkip: true,
                    maxTicks: 10,
                    padding: 0,
                },

                min: 0,
                max: 10,

            },

            y: {
                title: { display: true, text: 'Value' },

            },
        },
    };


    const handleDownloadExcelPressure = () => {
        const dataToDownloadPressure = pressureData.length ? pressureData : [];
        const worksheetPressure = XLSX.utils.json_to_sheet(dataToDownloadPressure);
        const workbookPressure = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbookPressure, worksheetPressure, 'Pressure');
        const excelBufferPressure = XLSX.write(workbookPressure, { bookType: 'xlsx', type: 'array' });
        const blobPressure = new Blob([excelBufferPressure], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blobPressure, 'pressureData.xlsx');
    };
    const handleDownloadPDFPressure = () => {
        const dataToDownloadPressure = pressureData.length ? pressureData : [];
        const docPressure = new jsPDF();
        docPressure.text('Pressure Monitoring', 10, 10);
        const tablePressure = dataToDownloadPressure.map(entry => [
            entry.timestamp.toLocaleString(),
            entry.pressure,
        ]);
        docPressure.autoTable({
            head: ['Timestamp', 'Pressure'],
            body: tablePressure,
        });
        docPressure.save('pressureData.pdf');
    };
    const handleDownloadWordPressure = () => {
        const dataToDownloadPressure = pressureData.length ? pressureData : [];
        const contentPressure = dataToDownloadPressure.map(row => Object.values(row)).join('\n');
        const blobPressure = new Blob([contentPressure], { type: 'text/plain;charset=utf-8' });
        saveAs(blobPressure, 'pressureData.txt');
    };

    const handleDownloadPressure = (format) => {
        if (format == "pdf") {
            handleDownloadPDFPressure();
        } else if (format == "excel") {
            handleDownloadExcelPressure();
        } else if (format == "word") {
            handleDownloadWordPressure();
        }
    };



    // CSV loading function
    const loadedCsvData = (path, setData) => {
        fetch(path)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then(csvText => {
                Papa.parse(csvText, {
                    header: true,
                    skipEmptyLines: true,
                    dynamicTyping: true,
                    complete: (result) => {
                        console.log('Parsed Data:', result.data); // Log parsed data to verify
                        setData(result.data);
                    }
                });
            })
            .catch(error => console.error('Error loading CSV:', error));
    };

    const [csvData, setCsvData] = useState([]);
    const [temperatureData, setTemperatureData] = useState([]);


    // Prepare chart data
    const temperatureChartData = {
        labels: temperatureData.map(row => row.timestamp.toLocaleTimeString()),  // X-axis will display time
        datasets: [
            {
                label: 'Temperature',
                data: temperatureData.map(row => row.temperature),
                borderColor: 'rgba(75, 192, 192, 1)', // Line color
                fill: false,
                tension: 0.1,
            }
        ],
    };

    // Chart options to add axis labels
    const temperatureChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'top' },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label || ''}: ${context.parsed.y}`
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x', // Enable horizontal panning
                    threshold: 10,
                    onPan: ({ chart }) => {
                        const { min, max } = chart.scales.x;
                        if (min <= chart.data.labels[0]) {
                            loadOlderData(); // Load older data if needed when panning
                        }
                    },
                },
                zoom: {
                    wheel: {
                        enabled: false,
                    },
                    drag: {
                        enabled: false,
                    },
                    mode: 'x',
                }
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Time',  // X-axis label
                },
                ticks: {

                    autoSkip: true,
                    maxTicks: 10,
                    padding: 0,

                },
                min: 0,  // Start from the first point
                max: 10,  // Display up to the 10th point (index 0 to 9)
            },
            y: {
                title: {
                    display: true,
                    text: '',  // Y-axis label
                },
            },
        },

    };

    const handleDownloadExcelTemperature = () => {
        const dataToDownloadTemperature = temperatureChartData.length > 0 ? temperatureChartData : csvData;
        const worksheetTemperature = XLSX.utils.json_to_sheet(dataToDownloadTemperature);
        const workbookTemperature = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbookTemperature, worksheetTemperature, 'Temperature');
        const excelBufferTemperature = XLSX.write(workbookTemperature, { bookType: 'xlsx', type: 'array' });
        const dataTemperature = new Blob([excelBufferTemperature], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        saveAs(dataTemperature, 'Temperature.xlsx');
    };
    const handleDownloadPDFTemperature = () => {
        const dataToDownloadTemperature = temperatureChartData.length > 0 ? temperatureChartData : csvData;
        const docTemperature = new jsPDF();
        docTemperature.text('Temperature Data', 10, 10);
        const tableDataTemperature = dataToDownloadTemperature.map(row => Object.values(row));
        docTemperature.table(10, 20, tableDataTemperature, Object.keys(dataToDownloadTemperature[0]));
        docTemperature.save('Temperature.pdf');

    };
    const handleDownloadWordTemperature = () => {
        const handleDownloadTemperature = temperatureChartData.length > 0 ? temperatureChartData : csvData;
        const contentTemperature = handleDownloadTemperature.map(row => Object.values(row)).join('\n');
        const blobTemperature = new Blob([contentTemperature], { type: 'text/plain;charset=utf-8' });
        saveAs(blobTemperature, 'Temperature.txt');
    };

    const handleDownloadTemperature = (format) => {
        if (format == "pdf") {
            handleDownloadPDFTemperature();
        } else if (format == "excel") {
            handleDownloadExcelTemperature();
        } else if (format == "word") {
            handleDownloadWordTemperature();
        }
    };



    //tiltmeter
    const [tiltData, setTiltData] = useState([]);
    const [selectedTiltAxis, setSelectedTiltAxis] = useState('x');


    // Specify the path to the CSV file
    const csvFilePath = '/bridge-main/src/components/Pages/fake_data_accel.csv';
    // Ensure this path is correct

    // Function to load and parse CSV from the file path
    useEffect(() => {
        fetch(csvFilePath)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then(csvText => {
                console.log('CSV Text:', csvText); // Log CSV content

                Papa.parse(csvText, {
                    header: true,
                    skipEmptyLines: true,
                    dynamicTyping: true,
                    complete: (result) => {
                        console.log('Parsed Data:', result.data); // Log parsed data
                        const parsedData = result.data.map(row => ({
                            timestamp: row.timestamp,
                            x: row.x,
                            y: row.y,
                            z: row.z
                        }));
                        setTiltData(parsedData); // Update state
                        console.log('Tilt Data:', parsedData); // Log updated tiltData
                    }
                });
            })
            .catch(error => console.error('Error loading CSV:', error));
    }, [csvFilePath]);


    const tiltGraphData = {
        labels: tiltData.map(row => row.timestamp), // X-axis as timestamp
        datasets: [

        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: '',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: '',
                },
                ticks: {
                    callback: function (value) {
                        return value; // Ensure timestamp labels are displayed correctly
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: '',
                }
            }
        }
    };
    const handleDownloadExcelTilemeter = () => {
        const dataToDownloadTilt = tiltGraphData.length > 0 ? tiltData : [];
        const worksheetTilt = XLSX.utils.json_to_sheet(dataToDownloadTilt);
        const workbookTilt = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbookTilt, worksheetTilt, "Tilt Data");
        const excelBufferTilt = XLSX.write(workbookTilt, { bookType: "xlsx", type: "array" });
        const dataTilt = new Blob([excelBufferTilt], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(dataTilt, "tiltData.xlsx");
    };
    const handleDownloadPDFTilt = () => {
        const dataToDownloadTilt = tiltGraphData.length > 0 ? tiltData : [];
        const docTilt = new jsPDF();
        docTilt.text("Tilt Data", 10, 10);
        const tableDataTilt = dataToDownloadTilt.map(entry => [entry.timestamp, entry.x, entry.y, entry.z]);
        docTilt.autoTable({ head: ["Timestamp", "X", "Y", "Z"], body: tableDataTilt });
        docTilt.save("tiltData.pdf");
    };

    const handleDownloadWordTilt = () => {
        const dataToDownloadTilt = tiltGraphData.length > 0 ? tiltData : [];
        const contentTilt = dataToDownloadTilt.map(entry => [entry.timestamp, entry.x, entry.y, entry.z]).join("\n");
        const blobTilt = new Blob([contentTilt], { type: "application/msword" });
        saveAs(blobTilt, "tiltData.doc");
    };

    const handleDownloadTilt = (format) => {
        if (format === "pdf") {
            handleDownloadPDFTilt();
        } else if (format === "excel") {
            handleDownloadExcelTilemeter();
        } else if (format === "word") {
            handleDownloadWordTilt();
        }
    };

    useEffect(() => {
        const fetchBatteryVoltage = async () => {
            const fetchedVoltage = 0;
            setBatteryVoltage(fetchedVoltage);
        };
        fetchBatteryVoltage();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(SensorData); // Change path to your CSV file
            const reader = response.body.getReader();
            const result = await reader.read();
            const decoder = new TextDecoder('utf-8');
            const csv = decoder.decode(result.value);
            const parsedData = Papa.parse(csv, { header: true });


            //General Dashboard
            const labels = parsedData.data.slice(0, 180).map(row => row.DateTime);

            const BatteryVoltage = parsedData.data.slice(0, 180).map(row => row.BatteryVoltage);
            const LoggerTemp = parsedData.data.slice(0, 180).map(row => row.LoggerTemp);

            // Sensor 1
            const Sensor1Temp = parsedData.data.slice(0, 180).map(row => row.S01TEMP);
            const Sensor1Freq = parsedData.data.slice(0, 180).map(row => row.S01hz);
            const Sensor1Eng = parsedData.data.slice(0, 180).map(row => row.S01Eng);

            // Sensor 2
            const Sensor2Temp = parsedData.data.slice(0, 180).map(row => row.S02TEMP);
            const Sensor2Freq = parsedData.data.slice(0, 180).map(row => row.S02Hz);
            const Sensor2Eng = parsedData.data.slice(0, 180).map(row => row.S02Eng);



            //INDIVIDUAL NUMERICAL VALUES (General Dashboard)
            const sum = BatteryVoltage.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
            const average = sum / BatteryVoltage.length;
            setAverageBatteryVoltage(average.toFixed(2));

            const sum1 = LoggerTemp.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
            const average1 = sum1 / LoggerTemp.length.toFixed(2);
            setAverageLoggerTemp(average1.toFixed(2));


            //Sensor 1
            const sum2 = Sensor1Temp.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
            const average2 = sum2 / Sensor1Temp.length;
            setAverageSensor1Temp(average2.toFixed(2));

            const sum3 = Sensor1Freq.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
            const average3 = sum3 / Sensor1Freq.length.toFixed(2);
            setAverageSensor1Freq(average3.toFixed(2));

            const sum4 = Sensor1Eng.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
            const average4 = sum4 / Sensor1Eng.length.toFixed(2);
            setAverageSensor1Eng(average4.toFixed(2));

            //Sensor 2
            const sum5 = Sensor2Temp.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
            const average5 = sum5 / Sensor2Temp.length;
            setAverageSensor2Temp(average5.toFixed(2));

            const sum6 = Sensor2Freq.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
            const average6 = sum6 / Sensor2Freq.length.toFixed(2);
            setAverageSensor2Freq(average6.toFixed(2));

            const sum7 = Sensor2Eng.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
            const average7 = sum7 / Sensor2Eng.length.toFixed(2);
            setAverageSensor2Eng(average7.toFixed(2));


            //Animation
            let count = 0;
            let count1 = 0;
            let count2 = 0;
            let count3 = 0;
            let count4 = 0;
            const animationInterval = setInterval(() => {
                if (count < average || count1 < average1 || count2 < average2 || count3 < average3 || count4 < average4) {
                    setAverageBatteryVoltage(count.toFixed(2));
                    setAverageLoggerTemp(count1.toFixed(2));
                    setAverageSensor1Temp(count2.toFixed(2));
                    setAverageSensor1Freq(count3.toFixed(2));
                    setAverageSensor1Eng(count4.toFixed(2));
                    count += average / 150; // Animation speed
                    count1 += average1 / 150;
                    count2 += average2 / 150;
                    count3 += average3 / 150;
                    count4 += average4 / 150;
                } else {
                    setAverageBatteryVoltage(average.toFixed(2));
                    setAverageLoggerTemp(average1.toFixed(2));
                    setAverageSensor1Temp(average2.toFixed(2));
                    setAverageSensor1Freq(average3.toFixed(2));
                    setAverageSensor1Eng(average4.toFixed(2));
                    clearInterval(animationInterval);
                }
            }, 20);



            setChartData({
                labels: labels,
                datasets: [
                    {
                        label: 'Date/Time vs Battery Voltage',
                        data: BatteryVoltage,
                        borderColor: 'blue',
                        borderWidth: 1,
                        pointBorderColor: 'black',
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        tension: 0,
                    }
                ]
            });
            setChartData1({
                labels: labels,
                datasets: [
                    {
                        label: 'Date/Time vs Logger Temperature',
                        data: LoggerTemp,
                        borderColor: 'red',
                        borderWidth: 1,
                        pointBorderColor: 'black',
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        tension: 0
                    }
                ]
            });

            //SENSOR DATA
            setChartData2({
                labels: labels,
                datasets: [
                    {
                        label: 'Date/Time vs Sensor 1 Temperature',
                        data: Sensor1Temp,
                        borderColor: 'blue',
                        borderWidth: 1,
                        pointBorderColor: 'black',
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        tension: 0
                    }
                ]
            });
            setChartData3({
                labels: labels,
                datasets: [
                    {
                        label: 'Date/Time vs Sensor 1 Frequency (in Hz)',
                        data: Sensor1Freq,
                        borderColor: 'red',
                        borderWidth: 1,
                        pointBorderColor: 'black',
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        tension: 0
                    }
                ]
            });
            setChartData4({
                labels: labels,
                datasets: [
                    {
                        label: 'Date/Time vs Sensor 1 Eng',
                        data: Sensor1Eng,
                        borderColor: 'green',
                        borderWidth: 1,
                        pointBorderColor: 'black',
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        tension: 0
                    }
                ]
            });
            setChartData5({
                labels: labels,
                datasets: [
                    {
                        label: 'Date/Time vs Sensor 2 Temp)',
                        data: Sensor2Temp,
                        borderColor: 'blue',
                        borderWidth: 1,
                        pointBorderColor: 'black',
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        tension: 0
                    }
                ]
            });
            setChartData6({
                labels: labels,
                datasets: [
                    {
                        label: 'Date/Time vs Sensor 2 Frequency (in Hz)',
                        data: Sensor2Freq,
                        borderColor: 'red',
                        borderWidth: 1,
                        pointBorderColor: 'black',
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        tension: 0
                    }
                ]
            });
            setChartData7({
                labels: labels,
                datasets: [
                    {
                        label: 'Date/Time vs Sensor 2 Eng',
                        data: Sensor2Eng,
                        borderColor: 'green',
                        borderWidth: 1,
                        pointBorderColor: 'black',
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        tension: 0
                    }
                ]
            });
        };

        fetchData();
    }, []);

    const [showSensorDetails, setshowSensorDetails] = useState(false);
    const [showaccelerometers, setshowaccelerometers] = useState(false);
    const [showstraingauges, setshowstraingauges] = useState(false);

    const accelerometerbtn = (e) => {
        e.preventDefault();
        setIsSelected(false);
        setshowaccelerometers(true);
        setshowstraingauges(false);
        setshowDashboard(false);
        setshowSensorDashboard(false);
        setshowModify(false);
        setshowSensorDetails(false);
        setshowBridgeDetails(false);
        setshowUser(false);

    }

    const straingaugebtn = (e) => {
        e.preventDefault();
        setIsSelected(false);
        setshowaccelerometers(false);
        setshowstraingauges(true);
        setshowDashboard(false);
        setshowSensorDashboard(false);
        setshowModify(false);
        setshowSensorDetails(false);
        setshowBridgeDetails(false);
        setshowUser(false);
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(accelerometer1);
                const reader = response.body.getReader();
                const result = await reader.read();
                const decoder = new TextDecoder('utf-8');
                const csv = decoder.decode(result.value);

                const parsedData = Papa.parse(csv, { header: true });
                console.log('Parsed Data:', parsedData); // Log this to see the structure

                if (Array.isArray(parsedData.data)) {
                    setAccelData1(parsedData.data);
                } else {
                    console.error('Parsed data is not an array:', parsedData.data);
                }
            } catch (error) {
                console.error('Error fetching or parsing CSV:', error);
            }
        };

        fetchData();  // Call the fetchData function here
    }, []);  // Dependency array is empty, so this effect runs once on component mount


    const data1 = {
        labels: Array.isArray(accelData1) ? accelData1.map((entry, index) => index) : [],
        datasets: selectedAxis1 === 'all' ? [
            {
                label: 'X-Axis',
                data: Array.isArray(accelData1) ? accelData1.map(entry => parseFloat(entry.x)) : [],
                borderColor: 'red',
                backgroundColor: 'rgba(255, 0, 0, 0.2)',
                pointRadius: 0,
                pointHoverRadius: 0,
            },
            {
                label: 'Y-Axis',
                data: Array.isArray(accelData1) ? accelData1.map(entry => parseFloat(entry.y)) : [],
                borderColor: 'blue',
                backgroundColor: 'rgba(0, 0, 255, 0.2)',
                pointRadius: 0,
                pointHoverRadius: 0,
            },
            {
                label: 'Z-Axis',
                data: Array.isArray(accelData1) ? accelData1.map(entry => parseFloat(entry.z)) : [],
                borderColor: 'green',
                backgroundColor: 'rgba(0, 255, 0, 0.2)',
                pointRadius: 0,
                pointHoverRadius: 0,
            },
        ] : [
            {
                label: `Time vs ${selectedAxis1.toUpperCase()} Axis`,
                data: Array.isArray(accelData1) ? accelData1.map(entry => parseFloat(entry[selectedAxis1])) : [],
                borderColor: selectedAxis1 === 'x' ? 'red' : selectedAxis1 === 'y' ? 'blue' : 'green',
                backgroundColor: selectedAxis1 === 'x' ? 'rgba(255, 0, 0, 0.2)' : selectedAxis1 === 'y' ? 'rgba(0, 0, 255, 0.2)' : 'rgba(0, 255, 0, 0.2)',
                pointRadius: 0,
                pointHoverRadius: 0,
            },
        ],
    };




    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [isSelected, setIsSelected] = useState(true);
    const [isSelected1, setIsSelected1] = useState(false);
    const [isSelected4, setIsSelected4] = useState(false);


    const [showUserDetails, setshowUserDetails] = useState(false);
    const [showDashboard, setshowDashboard] = useState(true);
    const [showSensorDashboard, setshowSensorDashboard] = useState(false);
    const [showModify, setshowModify] = useState(false);

    //MODIFY SECTION
    const [id, setId] = useState('');
    const bridgeName = localStorage.getItem('bridgeName');

    const [statesList, setStatesList] = useState([]);

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        setUserData(prevData => ({ ...prevData, country: selectedCountry }));

        // Fetch states based on selected country
        if (selectedCountry === 'USA') {
            setStatesList(['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']);
        } else if (selectedCountry === 'India') {
            setStatesList(['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal']);
        } else {
            setStatesList([]);
        }

        // Reset state selection
        setUserData(prevData => ({ ...prevData, state: '' }));
    };

    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        setUserData(prevData => ({ ...prevData, state: selectedState }));
    };



    console.log(statesList);




    // console.log(bridgeName);
    useEffect(() => {
        const findBridgeID = async () => {
            try {
                const response = await axios.get(`https://shmbackend.dext.site/bridge/bridgeid?bridgeName=${bridgeName}`)
                if (response.status >= 200 && response.status < 300) {
                    console.log(response.data)
                    setId(response.data)
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }

        };

        findBridgeID();
    }, [bridgeName]);


    //   const [userData, setUserData] = useState({
    //     country: '',
    //     state: '',
    //     city:'',
    //     coordinates: '',
    //     division: '',
    //     location: '',
    //     bridgeName: '',

    //     adminEmail: '',
    //     adminName: '',
    //     adminPhone: '',
    //     managerEmail: '',
    //     managerName: '',
    //     managerPhone: '',
    //     ownerEmail: '',
    //     ownerName: '',
    //     ownerPhone: '',

    //     adminEmail2: '',
    //     adminName2: '',
    //     adminPhone2: '',
    //     managerEmail2: '',
    //     managerName2: '',
    //     managerPhone2: '',
    //     ownerEmail2: '',
    //     ownerName2: '',
    //     ownerPhone2: '',

    //     adminEmail3: '',
    //     adminName3: '',
    //     adminPhone3: '',
    //     managerEmail3: '',
    //     managerName3: '',
    //     managerPhone3: '',
    //     ownerEmail3: '',
    //     ownerName3: '',
    //     ownerPhone3: '',

    //     managerEmail4: '',
    //     managerName4: '',
    //     managerPhone4: '',

    //     managerEmail5: '',
    //     managerName5: '',
    //     managerPhone5: '',

    //     managerEmail6: '',
    //     managerName6: '',
    //     managerPhone6: '',

    //     noofgirders: '',
    //     nobridgespan:'',

    //     admin1countryCode:'code',
    //     admin2countryCode:'code',
    //     admin3countryCode:'code',

    //     manager1countryCode:'code',
    //     manager2countryCode:'code',
    //     manager3countryCode:'code',
    //     manager4countryCode:'code',
    //     manager5countryCode:'code',
    //     manager6countryCode:'code',

    //     owner1countryCode:'code',
    //     owner2countryCode:'code',
    //     owner3countryCode:'code',
    // });
    const initialUserData = {
        country: '',
        state: '',
        city: '',
        coordinates: '',
        division: '',
        location: '',
        bridgeName: '',
        adminInfo: {
            adminEmail: '',
            adminName: '',
            adminPhone: '',
            adminEmail2: '',
            adminName2: '',
            adminPhone2: '',
            adminEmail3: '',
            adminName3: '',
            adminPhone3: '',
            admin1countryCode: 'code',
            admin2countryCode: 'code',
            admin3countryCode: 'code',
        },
        managerInfo: {
            managerEmail: '',
            managerName: '',
            managerPhone: '',
            managerEmail2: '',
            managerName2: '',
            managerPhone2: '',
            managerEmail3: '',
            managerName3: '',
            managerPhone3: '',
            managerEmail4: '',
            managerName4: '',
            managerPhone4: '',
            managerEmail5: '',
            managerName5: '',
            managerPhone5: '',
            managerEmail6: '',
            managerName6: '',
            managerPhone6: '',
            manager1countryCode: 'code',
            manager2countryCode: 'code',
            manager3countryCode: 'code',
            manager4countryCode: 'code',
            manager5countryCode: 'code',
            manager6countryCode: 'code',
        },
        ownerInfo: {
            ownerEmail: '',
            ownerName: '',
            ownerPhone: '',
            ownerEmail2: '',
            ownerName2: '',
            ownerPhone2: '',
            ownerEmail3: '',
            ownerName3: '',
            ownerPhone3: '',
            owner1countryCode: 'code',
            owner2countryCode: 'code',
            owner3countryCode: 'code',
        },
        noofgirders: '',
        nobridgespan: ''
    };

    const [userData, setUserData] = useState(initialUserData)

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!id) {
                    return;
                }
                const response = await axios.get(`https://shmbackend.dext.site/bridge/getbridge/${id}`);
                if (response.status >= 200 && response.status < 300) {
                    console.log(response.data);
                    const { country, state, city, coordinates, division, zipCode, bridgeName,
                        adminName, adminEmail, adminPhone, adminName2, adminEmail2, adminPhone2,
                        adminName3, adminEmail3, adminPhone3, managerName, managerEmail, managerPhone,
                        managerName2, managerEmail2, managerPhone2, managerName3, managerEmail3, managerPhone3,
                        managerName4, managerEmail4, managerPhone4, managerName5, managerEmail5, managerPhone5,
                        managerName6, managerEmail6, managerPhone6, ownerName, ownerEmail, ownerPhone, ownerName2,
                        ownerEmail2, ownerPhone2, ownerName3, ownerEmail3, ownerPhone3, nobridgespan, noofgirders,
                        admin1countryCode, admin2countryCode, admin3countryCode, owner1countryCode, owner2countryCode,
                        owner3countryCode, manager1countryCode, manager2countryCode, manager3countryCode, manager4countryCode,
                        manager5countryCode, manager6countryCode } = response.data;

                    setUserData({
                        country, state, city, coordinates, division, zipCode, bridgeName,
                        adminName, adminEmail, adminPhone, adminName2, adminEmail2, adminPhone2,
                        adminName3, adminEmail3, adminPhone3, managerName, managerEmail, managerPhone,
                        managerName2, managerEmail2, managerPhone2, managerName3, managerEmail3, managerPhone3,
                        managerName4, managerEmail4, managerPhone4, managerName5, managerEmail5, managerPhone5,
                        managerName6, managerEmail6, managerPhone6, ownerName, ownerEmail, ownerPhone, ownerName2,
                        ownerEmail2, ownerPhone2, ownerName3, ownerEmail3, ownerPhone3, nobridgespan, noofgirders,
                        admin1countryCode, admin2countryCode, admin3countryCode, owner1countryCode, owner2countryCode,
                        owner3countryCode, manager1countryCode, manager2countryCode, manager3countryCode, manager4countryCode,
                        manager5countryCode, manager6countryCode
                    });
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [id]);



    const updateData = async (dataToUpdate) => {
        if (!userData.adminName || !userData.adminEmail || !userData.adminPhone || userData.admin1countryCode === 'code') {
            enqueueSnackbar('Please Add Atleast One Admin!', { variant: 'error' });
        }
        else if (!userData.managerName || !userData.managerEmail || !userData.managerPhone || userData.manager1countryCode === 'code') {
            enqueueSnackbar('Please Add Atleast One Manager!', { variant: 'error' });
        }
        else if (!userData.ownerName || !userData.ownerEmail || !userData.ownerPhone || userData.owner1countryCode === 'code') {
            enqueueSnackbar('Please Add Atleast One Owner!', { variant: 'error' });
        }
        else {
            try {
                const dataToUpdate = {
                    country: userData.country,
                    state: userData.state,
                    city: userData.city,
                    coordinates: userData.coordinates,
                    division: userData.division,
                    location: userData.zipCode,
                    bridgeName: userData.bridgeName,

                    adminEmail: userData.adminEmail,
                    adminName: userData.adminName,
                    adminPhone: userData.adminPhone,
                    managerEmail: userData.managerEmail,
                    managerName: userData.managerName,
                    managerPhone: userData.managerPhone,
                    ownerEmail: userData.ownerEmail,
                    ownerName: userData.ownerName,
                    ownerPhone: userData.ownerPhone,

                    adminEmail2: userData.adminEmail2,
                    adminName2: userData.adminName2,
                    adminPhone2: userData.adminPhone2,
                    managerEmail2: userData.managerEmail2,
                    managerName2: userData.managerName2,
                    managerPhone2: userData.managerPhone2,
                    ownerEmail2: userData.ownerEmail2,
                    ownerName2: userData.ownerName2,
                    ownerPhone2: userData.ownerPhone2,

                    adminEmail3: userData.adminEmail3,
                    adminName3: userData.adminName3,
                    adminPhone3: userData.adminPhone3,
                    managerEmail3: userData.managerEmail3,
                    managerName3: userData.managerName3,
                    managerPhone3: userData.managerPhone3,
                    ownerEmail3: userData.ownerEmail3,
                    ownerName3: userData.ownerName3,
                    ownerPhone3: userData.ownerPhone3,

                    managerEmail4: userData.managerEmail4,
                    managerName4: userData.managerName4,
                    managerPhone4: userData.managerPhone4,

                    managerEmail5: userData.managerEmail5,
                    managerName5: userData.managerName5,
                    managerPhone5: userData.managerPhone5,

                    managerEmail6: userData.managerEmail6,
                    managerName6: userData.managerName6,
                    managerPhone6: userData.managerPhone6,

                    admin1countryCode: userData.admin1countryCode,
                    admin2countryCode: userData.admin2countryCode,
                    admin3countryCode: userData.admin3countryCode,

                    owner1countryCode: userData.owner1countryCode,
                    owner2countryCode: userData.owner2countryCode,
                    owner3countryCode: userData.owner3countryCode,

                    manager1countryCode: userData.manager1countryCode,
                    manager2countryCode: userData.manager2countryCode,
                    manager3countryCode: userData.manager3countryCode,
                    manager4countryCode: userData.manager4countryCode,
                    manager5countryCode: userData.manager5countryCode,
                    manager6countryCode: userData.manager6countryCode,
                };
                const response = await axios.put(`https://shmbackend.dext.site/bridge/updatebridge/${id}`, dataToUpdate);
                if (response.status >= 200 && response.status < 300) {
                    alert('Data Updated Successfully!')
                    console.log('Data updated successfully:', response.data);
                    return response.data;
                }
            }
            catch (error) {
                console.error('Error updating data:', error);
                throw error;
            }
        }
    };


    //SensorData
    const [sensorDataList, setSensorDataList] = useState([]);

    const [sensorData, setsensorData] = useState({
        sensortype: '',
        spanno: '',
        girderno: '',
        manualLocation: '',
    });
    // Maximum number of points to display in the chart



    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!id) {
                    return;
                }
                const response = await axios.get(`https://shmbackend.dext.site/bridge/getsensor/${id}`);
                if (response.status >= 200 && response.status < 300) {
                    console.log(response.data);
                    setSensorDataList(response.data);
                    const { sensortype, spanno, girderno, manualLocation } = response.data;

                    setsensorData({ sensortype, spanno, girderno, manualLocation });
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [id, showSensorDetails]);


    const updateSensorData = async (SensordataToUpdate) => {
        try {
            const SensordataToUpdate = {
                sensortype: sensorData.sensortype,
                spanno: sensorData.spanno,
                girderno: sensorData.girderno,
                manualLocation: sensorData.manualLocation,
            };
            const response = await axios.put(`https://shmbackend.dext.site/bridge/updatesensor/${id}`, SensordataToUpdate);
            if (response.status >= 200 && response.status < 300) {
                alert('Data Updated Successfully!')
                console.log('Data updated successfully:', response.data);
                return response.data;
            }
        }
        catch (error) {
            console.error('Error updating data:', error);
            throw error; // Optionally rethrow the error to handle it in the calling code
        }
    };

    const mapRef = useRef(null);

    useEffect(() => {
        if (!mapRef?.current) return;

        const map = new Map({
            basemap: "osm",
        });
        const view = new MapView({
            map: map,
            container: mapRef.current,
            center: [55, 25],
            zoom: 13,
        });
        return () => view && view.destroy();
    }, []);


    const weatherapi = {
        key: "ce6e8efc9563bba968f6c6284d0253df",
        base: "https://api.openweathermap.org/data/2.5/",
    };

    const [Weather, setWeather] = useState('');

    useEffect(() => {
        // Fetch weather data for 'Pune' when component mounts
        fetch(`${weatherapi.base}weather?q=${userData.city}&units=metric&APPID=${weatherapi.key}`)
            .then((res) => res.json())
            .then((result) => {
                setWeather(result);
            })
            .catch(error => {
                console.error('Error fetching weather data:', error);
            });
    }, [weatherapi.base, weatherapi.key, userData.city]);




    const removeAdmin1 = (e) => {
        e.preventDefault();
        setUserData({
            adminName: null,
            adminEmail: null,
            adminPhone: null
        });
    };
    const removeAdmin2 = (e) => {
        e.preventDefault();
        setUserData({
            adminName2: null,
            adminEmail2: null,
            admin2countryCode: null,
            adminPhone2: null
        });
    };
    const removeAdmin3 = (e) => {
        e.preventDefault();
        setUserData({
            adminName3: null,
            adminEmail3: null,
            admin3countryCode: null,
            adminPhone3: null
        });
    };


    const removeManager1 = (e) => {
        e.preventDefault();
        setUserData({
            managerName: null,
            managerEmail: null,
            manager1countryCode: null,
            managerPhone: null
        });
    };
    const removeManager2 = (e) => {
        e.preventDefault();
        setUserData({
            managerName2: null,
            managerEmail2: null,
            manager2countryCode: null,
            managerPhone2: null
        });
    };
    const removeManager3 = (e) => {
        e.preventDefault();
        setUserData({
            managerName3: null,
            managerEmail3: null,
            manager3countryCode: null,
            managerPhone3: null
        });
    };
    const removeManager4 = (e) => {
        e.preventDefault();
        setUserData({
            managerName4: null,
            managerEmail4: null,
            manager4countryCode: null,
            managerPhone4: null
        });
    };
    const removeManager5 = (e) => {
        e.preventDefault();
        setUserData({
            managerName5: null,
            managerEmail5: null,
            manager5countryCode: null,
            managerPhone5: null
        });
    };
    const removeManager6 = (e) => {
        e.preventDefault();
        setUserData({
            managerName6: null,
            managerEmail6: null,
            manager6countryCode: null,
            managerPhone6: null
        });
    };


    const removeOwner1 = (e) => {
        e.preventDefault();
        setUserData({
            ownerName: null,
            ownerEmail: null,
            owner1countryCode: null,
            ownerPhone: null
        });
    };
    const removeOwner2 = (e) => {
        e.preventDefault();
        setUserData({
            ownerName2: null,
            ownerEmail2: null,
            owner2countryCode: null,
            ownerPhone2: null
        });
    };
    const removeOwner3 = (e) => {
        e.preventDefault();
        setUserData({
            ownerName3: null,
            ownerEmail3: null,
            owner3countryCode: null,
            ownerPhone3: null
        });
    };


    const UserDetails = () => {
        setshowUserDetails(!showUserDetails);
    };

    const Dashboard = () => {
        setIsSelected(!isSelected);
        setIsSelected1(false);
        setIsSelected4(false);
        setshowDashboard(!showDashboard);
        setshowSensorDashboard(false);
        setshowModify(false);
        setshowSensorDetails(false);
        setshowBridgeDetails(false);
        setshowUser(false);
        setshowaccelerometers(false);
        setshowstraingauges(false);
    };

    const SensorDashboard = () => {
        setIsSelected1(!isSelected1);
        setIsSelected(false);
        setIsSelected4(false);
        setshowSensorDashboard(!showSensorDashboard);
        setshowDashboard(false);
        setshowModify(false);
        setshowBridgeDetails(false);
        setshowSensorDetails(false);
        setshowUser(false);
        setshowaccelerometers(false);
        setshowstraingauges(false);
    };

    const RedirectHome = () => {
        navigate('../home')
    };


    const Modify = () => {
        setshowSensorDashboard(false);
        setshowDashboard(false);
        setIsSelected1(false);
        setIsSelected(false);
        setshowSensorDetails(false);
        setshowUser(false);
        setshowModify(!showModify);
        setshowBridgeDetails(true);
        setIsSelected4(!isSelected4);
        setshowaccelerometers(false);
        setshowstraingauges(false);
    };

    const [showBridgeDetails, setshowBridgeDetails] = useState(false);

    const [showUser, setshowUser] = useState(false);

    const showBridgeInfo = () => {
        setshowBridgeDetails(true);
        setshowSensorDetails(false);
        setshowUser(false);
    };

    const showSensorInfo = () => {
        setshowSensorDetails(true);
        setshowBridgeDetails(false);
        setshowUser(false);
    };

    const showUserInfo = () => {
        setshowUser(true);
        setshowSensorDetails(false);
        setshowBridgeDetails(false);
    }

    const Logout = async () => {
        try {
            const token = localStorage.getItem('authToken'); // Corrected key
            console.log(token);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}` // Correct interpolation
                }
            };

            const response = await axios.post('https://shmbackend.dext.site/logout', {}, config);

            if (response.status === 200) {
                console.log(response.data);
                localStorage.removeItem('authToken');
                navigate('/');
            } else {
                console.error('Failed to fetch data:', response.statusText);
            }
        } catch (error) {
            console.error('Failed to fetch data:', error.message);
        }
    };



    const [accelData, setAccelData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [dataSource, setDataSource] = useState('api');
    const [selectedAxis, setSelectedAxis] = useState('all');
    const [selectedFileName, setSelectedFileName] = useState('');
    const [uploadMessage, setUploadMessage] = useState('');
    const [batteryData, setBatteryData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [batteryVoltage, setBatteryVoltage] = useState(0); // To store fetched battery voltage
    const [selectedVoltage, setSelectedVoltage] = useState(0); // User-selected voltage
    const [range, setRange] = useState([0, 25, 50, 75, 100]); // Default range for arcs
    const [isEditOpen, setIsEditOpen] = useState(false); // Modal visibility
    const [newVoltage, setNewVoltage] = useState(selectedVoltage); // To handle user input voltage
    const [newRange, setNewRange] = useState(range); // To handle user input range
    const [isLoading, setIsLoading] = useState(true);
    const [liveData, setLiveData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const maxDataPoints = 60; // Maximum number of points to display in the chart

    const [sensorNumbers, setSensorNumbers] = useState([]); // List of sensor numbers
    const [selectedSensorNumber, setSelectedSensorNumber] = useState(''); // Currently selected sensor number
    const [newValue, setnewValue] = useState("");

    useEffect(() => {
        const fetchSensorNumbers = async () => {
            try {
                const response = await axios.get('https://shmbackend.dext.site/api/sensor-data/distinct-sensor-numbers');
                if (response.status >= 200 && response.status < 300) {
                    setSensorNumbers(response.data);
                } else {
                    console.error(`Failed to fetch sensor numbers: ${response.status} ${response.statusText}`);
                }
            } catch (error) {
                console.error('Error fetching sensor numbers:', error);
            }
        };

        fetchSensorNumbers();
    }, []);






    const filterDataByDateRange = (start, end) => {
        console.log(accelData);
        const filtered = accelData.filter(entry => entry.timestamp >= start && entry.timestamp <= end);
        console.log('Filtered Data:', filtered);
        setFilteredData(filtered);
        console.log(filtered); // Logging first 60 entries
        setLiveData(filtered);
    };

    useEffect(() => {
        filterDataByDateRange(startDate, endDate);
    }, [startDate, endDate, accelData]);



    const liveChartData = {

        labels: liveData.map(entry => entry.timestamp.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })), // Directly use the timestamp as is
        datasets: selectedAxis === 'all'
            ? [
                { label: 'X-Axis', data: liveData.map(entry => entry.x), borderColor: 'red', fill: false },
                { label: 'Y-Axis', data: liveData.map(entry => entry.y), borderColor: 'green', fill: false },
                { label: 'Z-Axis', data: liveData.map(entry => entry.z), borderColor: 'blue', fill: false }
            ]
            : selectedAxis === 'x'
                ? [{ label: 'X-Axis', data: liveData.map(entry => entry.x), borderColor: 'red', fill: false }]
                : selectedAxis === 'y'
                    ? [{ label: 'Y-Axis', data: liveData.map(entry => entry.y), borderColor: 'green', fill: false }]
                    : [{ label: 'Z-Axis', data: liveData.map(entry => entry.z), borderColor: 'blue', fill: false }],
    };




    const chartRef = useRef(null);

    const [xMin, setXMin] = useState(0);
    const [xMax, setXMax] = useState(10);

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { position: 'top' },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.dataset.label || ''}: ${context.parsed.y}`
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x', // Enable horizontal panning
                    threshold: 10,
                    onPan: ({ chart }) => {
                        const { min, max } = chart.scales.x;
                        if (min <= chart.data.labels[0]) {
                            loadOlderData(); // Load older data if needed when panning
                        }
                    },
                },
                zoom: {
                    wheel: {
                        enabled: false,
                    },
                    drag: {
                        enabled: false,
                    },
                    mode: 'x',
                }
            }
        },
        scales: {
            x: {
                // No need to specify type if it's already properly registered

                title: { display: true, text: 'Time' },
                ticks: {
                    autoSkip: true,
                    maxTicks: 10,
                    padding: 0,
                },

                min: xMin,
                max: xMax,
            },

            y: {
                title: { display: true, text: 'Value' },

            },
        },
    };





    const loadOlderData = () => {
        setLiveData((prevData) => {
            const additionalData = accelData.slice(Math.max(0, prevData.length - 60 - maxDataPoints), prevData.length - maxDataPoints);
            return [...additionalData, ...prevData];
        });
    };


    const focusOnLastTick = () => {
        const newMax = liveData.length - 1;
        const newMin = Math.max(0, newMax - 10); // Adjust the range as needed

        setXMin(newMin);
        setXMax(newMax);
        console.log(xMin);
        console.log(xMax);
    };

    useEffect(() => {
        // Update the x-axis limits whenever liveData changes
        if (liveData.length > 0) {
            focusOnLastTick();
        }
    }, [liveData, xMin, xMax]);


    // Handle CSV upload
    const handleCSVUpload = (file) => {
        setSelectedFileName(file.name); // Set the file name in the state

        const reader = new FileReader();
        reader.onload = (event) => {
            const csvData = event.target.result;
            const parsedData = Papa.parse(csvData, {
                header: true, // Assuming the CSV has headers
                dynamicTyping: true, // Auto-convert to appropriate data types
            });

            if (parsedData.errors.length) {
                console.error("Error parsing CSV: ", parsedData.errors);
                setUploadMessage("Error uploading file.");
                return;
            }

            // Only take the first 300 values
            const limitedData = parsedData.data.slice(0, 300).map(entry => ({
                timestamp: new Date(`${entry.date} ${entry.time}`), // Assuming the date and time are separate columns
                x: entry['Accel X(g)'],
                y: entry['Accel Y(g)'],
                z: entry['Accel Z(g)'],
            }));

            setAccelData(limitedData);
            setFilteredData(limitedData);
            setUploadMessage('File has uploaded successfully');
        };

        reader.readAsText(file);
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFileName(file.name); // Store the selected file name
            setUploadMessage(''); // Clear any previous upload message
            handleCSVUpload(file); // Call the upload function
        }
    };


    // Handle data source change
    const handleDataSourceChange = (value) => {
        setDataSource(value);
    };


    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.update();
        }
    }, [liveData, selectedAxis]);


    <Line ref={chartRef} data={liveChartData} options={chartOptions} />


    // State to handle dropdown visibility
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isManagerDropdownOpen, setIsManagerDropdownOpen] = useState(false);


    // State to store admin names
    const [adminNames, setAdminNames] = useState([]);
    const [managerNames, setManagerNames] = useState([]);

    // Effect to update adminNames when userData changes
    useEffect(() => {
        // Populate admin names dynamically from userData
        const adminList = [
            userData.adminName,
            userData.adminName2,
            userData.adminName3,
        ];


        // Populate manager names dynamically from userData
        const managerList = [
            userData.managerName,
            userData.managerName2,
            userData.managerName3,
            userData.managerName4,
            userData.managerName5,
            userData.managerName6,
        ];


        // Filter out any empty or undefined values if needed
        setAdminNames(adminList.filter(admin => admin));
        setManagerNames(managerList.filter(manager => manager));
    }, [userData]); // Depend on userData so it updates when userData changes

    const [nobridgespan, setnobridgespan] = useState('');
    const [selectedOptions, setSelectedOptions] = useState(Array.from({ length: nobridgespan }, () => ''));
    const dropdownRef = useRef(null);
    const spanRef = useRef(null);

    const managerDropdownRef = useRef(null);
    const managerSpanRef = useRef(null);


    // State to toggle the visibility of the input field
    const [isFormVisible, setIsFormVisible] = useState(false);
    // State to hold the input value (number)
    const [sensorValue, setSensorValue] = useState('');
    const [girderValue, setGirderValue] = useState('');
    const [bridgeSpans, setBridgeSpans] = useState([]);
    const [girders, setGirders] = useState([]);
    const [selectedBridgeSpan, setSelectedBridgeSpan] = useState('');
    const [selectedGirder, setSelectedGirder] = useState('');
    const [sensorSrNo, setSensorSrno] = useState('');
    const [sensorLocation, setSensorLocation] = useState('');


    // Function to toggle form visibility
    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };


    // Handle input change for the number of bridge spans
    const handleInputChange = (e) => {
        const value = e.target.value;

        // Handle empty value case (when user deletes everything)
        if (value === '') {
            setSensorValue('');
            setBridgeSpans([]);
        } else {
            const numericValue = Math.min(Number(value), 50); // Limit the value to 50
            setSensorValue(numericValue);

            // Create an array of options for the dropdown based on the entered value
            const spansArray = Array.from({ length: numericValue }, (_, index) => index + 1);
            setBridgeSpans(spansArray);
        }
    };

    // Handle input change for the number of girders
    const handleGirderInputChange = (e) => {
        const value = e.target.value;

        // Handle empty value case (when user deletes everything)
        if (value === '') {
            setGirderValue('');
            setGirders([]);
        } else {
            const numericValue = Math.min(Number(value), 20); // Limit the value to 50
            setGirderValue(numericValue);

            // Create an array of options for the dropdown based on the entered value
            const girdersArray = Array.from({ length: numericValue }, (_, index) => index + 1);
            setGirders(girdersArray);
        }
    };

    // Handle the bridge span selection
    const handleBridgeSpanChange = (e) => {
        setSelectedBridgeSpan(e.target.value);
    };


    const handleSensorSrNoChange = (e) => {
        setSensorSrno(e.target.value);
    };


    const handleSensorLocationChange = (e) => {
        setSensorLocation(e.target.value);
    };

    const handleSensorLocationChange2 = (e) => {
        setSensorLocation(e.target.value);

    };


    const handleRemove = () => {
        setSensorLocation('');
        setSensorSrno('');
        setSensorValue('');
        setGirderValue('');
        setSelectedBridgeSpan('');
        setSelectedGirder('');

    };



    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSpanChange = (e) => {
        setnobridgespan(parseInt(e.target.value));
    }

    // Handle the girder selection
    const handleGirderChange = (e) => {
        setSelectedGirder(e.target.value);
    };

    // Toggle dropdown visibility for manager
    const toggleManagerDropdown = () => {
        setIsManagerDropdownOpen(!isManagerDropdownOpen);
    };

    const [sensortype, setsensortype] = useState('');

    const handleSensorTypeChange = (e) => {
        setsensortype(e.target.value);
    };


    // Close dropdown if clicked outside
    const handleClickOutside = (event) => {
        if (
            (dropdownRef.current && !dropdownRef.current.contains(event.target) && !spanRef.current.contains(event.target)) &&
            (managerDropdownRef.current && !managerDropdownRef.current.contains(event.target) && !managerSpanRef.current.contains(event.target))
        ) {
            setIsDropdownOpen(false);
            setIsManagerDropdownOpen(false);
        }
    };

    const [sensortype2, setsensortype2] = useState("");
    const [sensorTypeOptions, setSensorTypeOptions] = useState([]); // State to store fetched data
    const [selectedSensor, setSelectedSensor] = useState("");
    const [sensorLocation2, setsensorLocation2] = useState("");

    // Handle changes in the sensor type dropdown (Dropdown 1)
    const handleSensorTypeChange2 = (e) => {
        setsensortype2(e.target.value);
        //setSelectedSensor(""); // Reset the sensor selection when sensor type changes
        setsensorLocation2(""); // Reset the sensor location as well
    };

    // Handle changes in the sensor selection dropdown (Dropdown 2)
    const handleSensorChangecombine = (e) => {
        setSelectedSensor(e.target.value);
        setnewValue(e.target.value);

        setshowaccelerometers(true);
        setshowstraingauges(false);
        setshowDashboard(true);
        setshowSensorDashboard(false);
        setshowModify(false);
        setshowSensorDetails(false);
        setshowBridgeDetails(false);
        setshowUser(false);
        
        
    };

    useEffect(() => {
        const fetchSensorType = async () => {
            try {
                const response = await axios.get(
                    `https://shmbackend.dext.site/bridge/unique-sensors?sensortype=${encodeURIComponent(sensortype2)}&bridgeid=${id}`
                );
                if (response.status >= 200 && response.status < 300) {
                    setSensorTypeOptions(response.data); // Store fetched data for dropdown 2
                } else {
                    console.error(`Failed to fetch sensor types: ${response.status} ${response.statusText}`);
                }
            } catch (error) {
                console.error("Error fetching sensor types:", error);
            }
        };

        // Fetch sensor options only when sensortype2 and id are available
        if (sensortype2 && id) {
            fetchSensorType();
        }
    }, [sensortype2, id]);  // Dependency array includes sensortype2 and id

    // Fetch sensor location based on the selected sensor from dropdown 2
    useEffect(() => {
        const fetchSensorLocation = async () => {
            if (selectedSensor) {
                try {
                    const response = await axios.get(
                        `https://shmbackend.dext.site/bridge/manual-location?bridgeSensorSrno=${selectedSensor}&bridgeid=${id}`
                    );
                    if (response.status >= 200 && response.status < 300) {
                        setsensorLocation2(response.data); // Update the sensor location input field
                    } else {
                        console.error(`Failed to fetch sensor location: ${response.status} ${response.statusText}`);
                    }
                } catch (error) {
                    console.error("Error fetching sensor location:", error);
                }
            }
        };

        // Fetch sensor location only if a sensor is selected
        if (selectedSensor && id) {
            fetchSensorLocation();
        }
    }, [selectedSensor, id]);  // Dependency array includes selectedSensor and id


    useEffect(() => {
        const fetchData = async () => {
            try {
                {/*if (!selectedSensor) {
                    console.warn("Selected Sensor is missing. Cannot fetch data.");
                    return;
                }*/}

                if (dataSource === 'api') { // Only fetch from API if 'api' is selected selectedSensor
                    //const ids = "sensor_1";
                    console.log(selectedSensor);

                    const response = await axios.get(`https://shmbackend.dext.site/api/sensor-data/sensor-number/${selectedSensor}`);


                    if (response.status >= 200 && response.status < 300) {
                        // Extract accelerometer data
                        console.log(response);
                        const sensorDataList = response.data.map(entry => ({
                            timestamp: new Date(entry.timestamp),
                            x: entry.accelerometerX,
                            y: entry.accelerometerY,
                            z: entry.accelerometerZ,
                        }));
                        console.log('Sensor Data List:', sensorDataList);
                        setLiveData(prevData => {

                            const updatedData = [...prevData, ...sensorDataList];

                            return updatedData.slice(-maxDataPoints); // Only show the last 60 points initially
                        });


                        // Store the full data set for panning purposes
                        setAccelData(sensorDataList);



                        // Extract temperature data
                        const tempData = response.data.map(entry => ({
                            timestamp: new Date(entry.timestamp),
                            temperature: entry.temperature, // Assuming 'temperature' field is present in the API response
                        }));
                        console.log('Temperature Data List:', tempData);
                        setTemperatureData(tempData);

                        //Extract Pressure Data
                        const pressureDataList = response.data.map(entry => ({
                            timestamp: new Date(entry.timestamp),
                            pressure: entry.pressure, // Assuming 'pressure' field is present in the API response
                        }));
                        console.log('Pressure Data List:', pressureDataList);
                        setPressureData(pressureDataList);

                        //Extract Battery Data
                        const batteryDataList = response.data.map(entry => ({
                            battery: entry.batteryVoltage,
                        }));
                        console.log('Battery Data List:', batteryDataList);
                        setBatteryData(batteryDataList);
                        const latestBatteryVoltage = batteryDataList.slice(-1)[0].battery;
                        setBatteryVoltage(latestBatteryVoltage);
                        filterDataByDateRange(new Date(), new Date());
                    } else {
                        console.error(`Failed to fetch data: ${response.status} ${response.statusText}`);
                    }
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            } finally {
                setIsLoading(false);
            }
        };
        // const interval = setInterval(fetchData, 120000);

        // Cleanup interval on component unmount
        // return () => clearInterval(interval);
        fetchData();
    }, [id, dataSource, selectedSensor]);


    const Navigate = useNavigate();
    const handleDropdownChange = (index, value) => {
        setSelectedOptions(prevOptions => {
            const newOptions = [...prevOptions];
            newOptions[index] = value;
            return newOptions;
        });
    };

    // Add event listener for clicks outside
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);



    const handleDownloadExcel = () => {
        const dataToDownload = filteredData.length ? filteredData : accelData;
        const worksheet = XLSX.utils.json_to_sheet(dataToDownload);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Accelerometer Data");
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "accelerometer_data.xlsx");
    };

    const handleDownloadPDF = () => {
        const dataToDownload = filteredData.length ? filteredData : accelData;
        const doc = new jsPDF();
        doc.text("Accelerometer Data", 10, 10);
        const tableData = dataToDownload.map(entry => [
            entry.timestamp.toLocaleString(),
            entry.x,
            entry.y,
            entry.z,
        ]);
        doc.autoTable({
            head: [['Timestamp', 'X', 'Y', 'Z']],
            body: tableData,
        });
        doc.save("accelerometer_data.pdf");
    };

    const handleDownloadWord = () => {
        const dataToDownload = filteredData.length ? filteredData : accelData;
        const content = dataToDownload.map(entry => {
            return `Timestamp: ${entry.timestamp.toLocaleString()}, X: ${entry.x}, Y: ${entry.y}, Z: ${entry.z}\n`;
        }).join('\n');

        const blob = new Blob([content], { type: "application/msword" });
        saveAs(blob, "accelerometer_data.doc");
    };
    const handleDownload = (format) => {
        if (format === "excel") {
            handleDownloadExcel();
        } else if (format === "pdf") {
            handleDownloadPDF();
        } else if (format === "word") {
            handleDownloadWord();
        }
    };

    // Calculate three months ago from the current date
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    const bid = localStorage.getItem('bid');





    const handleSensorFormSubmit = async (e) => {
        e.preventDefault();

        if (!sensorLocation || !sensorSrNo || !sensorValue || !selectedBridgeSpan || !selectedGirder || !sensortype || !girderValue) {
            enqueueSnackbar('Please fill all the fields!', { variant: 'error' });

        } else {
            try {
                setLoading(true);
                const sensorData = [];

                sensorData.push({
                    totalnoofspanno: sensorValue,
                    totalnoofgirder: girderValue,
                    sensortype: sensortype,
                    bridgeSensorSrno: sensorSrNo,
                    spanno: selectedBridgeSpan,
                    girderno: selectedGirder,
                    manualLocation: sensorLocation,
                });


                // Post sensor data for each location separately
                for (const data of sensorData) {
                    const response = await axios.post(`https://shmbackend.dext.site/bridge/addSensorData/${bid}`, [data]);
                    if (response.status >= 200 && response.status < 300) {
                        console.log('Sensor Added Successfully:', data);
                        enqueueSnackbar('Sensor Added Successfully!', { variant: 'success' });
                        Navigate('../home/dashboard');
                    }
                }
            } catch (error) {

                console.error('Error submitting form: ', error);
                enqueueSnackbar('Failed to submit form. Please try again later.', { variant: 'error' });

            } finally {
                setLoading(false);

                setSensorLocation('');
                setSensorSrno('');
                setSensorValue('');
                setGirderValue('');
                setSelectedBridgeSpan('');
                setSelectedGirder('');


            }
        }


    };

    ;




    return (
        <>
            <div className="flex fixed z-50 w-full justify-center bg-gray-100 py-2 shadow-xl">
                <div className='w-1/2'>
                    <img className='h-10 pt-2 cursor-pointer px-5' src={logo2} alt="" />
                </div>
                <div className='w-full text-center pt-1'>
                    <h1 className='text-2xl ml-8 font-semibold text-center'>Structural Health Monitoring Dashboard</h1>
                </div>
                <div className='w-1/2 text-right'>
                    <button className='px-2'><MdSearch size={36} /></button>
                    <button className='px-2'><MdNotifications size={36} /></button>
                    <button onClick={UserDetails} className='px-2'><MdPerson onClick={UserDetails} size={36} /></button>
                </div>
            </div>

            <nav className='w-24 bg-gray-300 fixed mt-14'>
                <div className='text-center'>
                    <button className='w-full py-3 hover:bg-gray-400' onClick={RedirectHome}><ul><FaBridge style={{ width: '100%', alignItems: 'center' }} size={40} />Bridge List</ul></button>
                    <hr /><hr />
                    <button className={`w-full py-3 ${isSelected ? 'bg-gray-400' : 'hover:bg-gray-400'}`} onClick={Dashboard}><ul><MdDashboard style={{ width: '100%', alignItems: 'center' }} size={40} />Dashboard</ul></button>
                    <hr /><hr />
                    <button className={`w-full py-3 ${isSelected1 ? 'bg-gray-400' : 'hover:bg-gray-400'}`} onClick={SensorDashboard}><ul><MdSensors style={{ width: '100%', alignItems: 'center' }} size={40} />Sensor Data</ul></button>
                    <hr /><hr />
                    <button className='w-full py-3 hover:bg-gray-400'><ul><MdDescription style={{ width: '100%', alignItems: 'center' }} size={40} />Report</ul></button>
                    <hr /><hr />
                    <button className='w-full py-3 hover:bg-gray-400'><ul><MdSettings style={{ width: '100%', alignItems: 'center' }} size={40} />Settings</ul></button>
                    <button className={`w-full py-3 ${isSelected4 ? 'bg-gray-400' : 'hover:bg-gray-400'}`} onClick={Modify}><ul><FaEdit style={{ width: '100%', alignItems: 'center' }} size={40} />Modify</ul></button>
                    <hr /><hr />
                </div>
            </nav>



            {showUserDetails && (
                <div className="w-1/12 z-50 fixed top-14 right-2 bg-gray-100 border shadow-md">
                    <div className='p-2 text-center'>Name</div>
                    <div className='flex cursor-pointer hover:bg-gray-200 p-2'><MdEdit size={24} style={{ paddingTop: '3px' }} />Edit Info</div>
                    <div className='flex cursor-pointer hover:bg-gray-200 p-2' onClick={Logout}><MdLogout size={24} style={{ paddingTop: '3px' }} />Log-out</div>
                </div>
            )}


            {showDashboard && (
                <>
                    {/*<div className='w-11/12 ml-32 p-6 pt-20 flex'>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={accelerometerbtn}>Accelerometer</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={straingaugebtn}>Strain Gauge</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={accelerometerbtn}>Deflection Gauge</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={accelerometerbtn}>Camera</button>
                    </div>*/}


                    <div className='w-11/12  ml-32 p-6 pt-4  '>

                        <h1 classsname='mt-28 text-black'>{userData.bridgeName}</h1>


                        <div className="mt-28 flex-col pt-1 pb-10 bg-gray-200" >
                            <div className="ml-20">
                                {/* Row 1: Country, State, Bridge Owner, Division, Zip Code */}
                                <div className="flex flex-wrap justify-between mb-4">
                                    <div className="flex flex-col items-center w-1/5 px-2">
                                        <label className="font-bold text-xl mb-2" htmlFor="country">Country</label>
                                        <input type="text" id="country" name="country" value={userData.country} readOnly className="border text-center rounded px-4 py-2 w-full bg-gray-100" placeholder="Enter your country" />
                                    </div>
                                    <div className="flex flex-col items-center w-1/5 px-2">
                                        <label className="font-bold text-xl mb-2" htmlFor="state">State</label>
                                        <input type="text" id="state" name="state" value={userData.state} readOnly className="border text-center rounded px-4 py-2 w-full bg-gray-100" placeholder="Enter your state" />
                                    </div>
                                    <div className="flex flex-col items-center w-1/5 px-2">
                                        <label className="font-bold text-xl mb-2" htmlFor="ownerName">Bridge Owner</label>
                                        <input type="text" id="ownerName" name="ownerName" value={userData.ownerName} readOnly className="border text-center rounded px-4 py-2 w-full bg-gray-100" placeholder="Enter the bridge owner" />
                                    </div>
                                    <div className="flex flex-col items-center w-1/5 px-2">
                                        <label className="font-bold text-xl mb-2" htmlFor="division">Division</label>
                                        <input type="text" id="division" name="division" value={userData.division} readOnly className="border text-center rounded px-4 py-2 w-full bg-gray-100" placeholder="Enter the division" />
                                    </div>
                                    <div className="flex flex-col items-center w-1/5 px-2">
                                        <label className="font-bold text-xl mb-2" htmlFor="zipCode">Zip Code</label>
                                        <input type="text" id="zipCode" name="zipCode" value={userData.zipCode} readOnly className="border text-center rounded px-4 py-2 w-full bg-gray-100" placeholder="Enter the zip code" />
                                    </div>
                                </div>

                                {/* Row 2: Name of Administrator and Manager centered in the middle */}
                                <div className="flex justify-center gap-8 mb-4">
                                    <div className="flex flex-col items-center w-1/5 px-2">
                                        <label className="font-bold text-xl mb-2" htmlFor="adminName">Name of Administrator</label>
                                        <span className="border text-center rounded px-4 py-2 w-full bg-white cursor-pointer" ref={spanRef} onClick={toggleDropdown}>
                                            {userData.adminName}
                                        </span>
                                        {/* Dropdown menu */}
                                        {isDropdownOpen && (
                                            <ul ref={dropdownRef} className="bg-white shadow-md border border-gray-300 rounded-md mt-2">
                                                {adminNames.map((admin, index) => (
                                                    <li key={index} className="px-4 cursor-pointer hover:bg-gray-100">{admin}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>

                                    <div className="flex flex-col items-center w-1/5 px-2">
                                        <label className="font-bold text-xl mb-2" htmlFor="managerName">Name of Manager</label>
                                        <span className="border text-center rounded px-4 py-2 w-full bg-white cursor-pointer" ref={managerSpanRef} onClick={toggleManagerDropdown}>
                                            {userData.managerName}
                                        </span>
                                        {/* Manager Dropdown menu */}
                                        {isManagerDropdownOpen && (
                                            <ul ref={managerDropdownRef} className="bg-white shadow-md border border-gray-300 rounded-md mt-2">
                                                {managerNames.map((manager, index) => (
                                                    <li key={index} className="px-4 cursor-pointer hover:bg-gray-100">{manager}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Add Sensor Button */}
                            <div className="ml-20">
                                <button className="p-2 bg-black text-white rounded-md mt-7" onClick={toggleFormVisibility}>Add Sensor</button>
                            </div>

                            {/* Add Sensor Form */}
                            {isFormVisible && (
                                <div className="flex flex-col mt-5 ml-20">
                                    <div className="flex mb-4">
                                        <div className="flex flex-col w-1/3 px-2">
                                            <label className="font-bold text-xl mb-2" htmlFor="sensorValue">Number of Bridge Spans</label>
                                            <input
                                                type="number"
                                                value={sensorValue}
                                                onChange={handleInputChange}
                                                className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                                placeholder="Enter sensor value"
                                            />
                                        </div>

                                        <div className="flex flex-col w-1/3 px-2">
                                            <label className="font-bold text-xl mb-2" htmlFor="bridgeSpan">Select Bridge Span</label>
                                            <select
                                                value={selectedBridgeSpan}
                                                onChange={handleBridgeSpanChange}
                                                className="p-2 border border-gray-300 rounded-md w-full"
                                                disabled={bridgeSpans.length === 0} // Disable if no spans
                                            >
                                                <option value="" disabled>Select a bridge span</option>
                                                {bridgeSpans.map((span) => (
                                                    <option key={span} value={span}>{span}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="flex flex-col w-1/3 px-2">
                                            <label className="font-bold text-xl mb-2" htmlFor="girderValue">Add Number of Girders</label>
                                            <input
                                                type="number"
                                                value={girderValue === '' ? '' : girderValue}
                                                onChange={handleGirderInputChange}
                                                className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                                placeholder="Enter number of girders"
                                                max="50"
                                            />
                                        </div>
                                        <div className="flex flex-col w-1/3 px-2">

                                            <label className="font-bold text-xl mb-2" htmlFor="girderValue">Select Girders</label>
                                            <select
                                                value={selectedGirder}
                                                onChange={handleGirderChange}
                                                className="p-2 border border-gray-300 rounded-md w-full"
                                                disabled={girders.length === 0} // Disable if no girders
                                            >
                                                <option value="" disabled>Select a girder</option>
                                                {girders.map((girder) => (
                                                    <option key={girder} value={girder}>
                                                        {girder}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                    </div>

                                    <div className="flex mb-4">
                                        <div className="flex flex-col w-1/3 px-2">
                                            <label className="font-bold text-xl mb-2" htmlFor="sensorType">Sensor Type</label>
                                            <select
                                                id="sensorType"
                                                onChange={handleSensorTypeChange}
                                                value={sensortype}
                                                className="p-2 border border-gray-300 rounded-md w-full"
                                            >
                                                <option value="" disabled>Select Sensor Type</option>
                                                <option value="Accelerometer">Accelerometer</option>
                                                <option value="Strain Gauge">Strain Gauge</option>
                                                <option value="Deflection Gauge">Deflection Gauge</option>
                                                <option value="Camera">Camera</option>
                                            </select>
                                        </div>

                                        <div className="flex flex-col w-1/3 px-2">
                                            <label className="font-bold text-xl mb-2" htmlFor="sensorSrNo">Bridge Sensor Sr. No.</label>
                                            <input
                                                type="text"
                                                value={sensorSrNo}
                                                onChange={handleSensorSrNoChange}
                                                className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                                placeholder="Enter sensor sr no."
                                            />
                                        </div>

                                        <div className="flex flex-col w-1/3 px-2">
                                            <label className="font-bold text-xl mb-2" htmlFor="sensorLocation">Sensor Location</label>
                                            <input
                                                type="text"
                                                value={sensorLocation}
                                                onChange={handleSensorLocationChange}
                                                className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                                placeholder="Enter sensor location"
                                            />
                                        </div>
                                    </div>

                                    {/* Centered Submit and Remove Buttons with 30px spacing */}
                                    <div className="flex justify-center gap-8 mt-4">
                                        <button onClick={handleSensorFormSubmit} className="bg-black text-white p-2 px-5">Submit</button>
                                        <button onClick={handleRemove} className="bg-pink-700 text-white p-2 px-4">Remove</button>
                                    </div>
                                </div>
                            )}

                            <div className='flex ml-16 mt-7'>

                                <div className="mb-4 px-5">
                                    <label htmlFor="sensortype" className="block  font-bold text-xl">Sensor Type:</label>
                                    <select id="sensortype2" onChange={handleSensorTypeChange2} name="sensortype2" value={sensortype2} className="border mt-2 border-gray-300 p-1 w-full rounded overflow-hidden shadow-md outline-none">
                                        <option value="" disabled>Select Sensor Type</option>
                                        <option value="Accelerometer">Accelerometer</option>
                                        <option value="Strain Gauge">Strain Gauge</option>
                                        <option value="Deflection Gauge">Deflection Gauge</option>
                                        <option value="Camera">Camera</option>
                                    </select>
                                </div>


                                <div>
                                    <label htmlFor="sensor-dropdown" className="block font-bold text-xl">
                                        Select Sensor:
                                    </label>
                                    <select
                                        id="sensor-dropdown"
                                        value={selectedSensor}
                                        onChange={handleSensorChangecombine}
                                        className="border mt-2 border-gray-300 p-1 w-full rounded overflow-hidden shadow-md outline-none"
                                    >
                                        <option value="" disabled>
                                            Select Sensor
                                        </option>
                                        {sensorTypeOptions.map((sensor, index) => (
                                            <option key={index} value={sensor.id}>
                                                {sensor}
                                            </option>
                                        ))}
                                    </select>
                                </div>


                                <div className=" ml-20">
                                    <h5 className="font-bold text-xl "> Sensor Location</h5>
                                    <input
                                        type="text"
                                        value={sensorLocation2}
                                        onChange={(e) => setsensorLocation2(e.target.value)}
                                        className="p-2 border border-gray-300 rounded-md mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Sensor location will appear here"
                                        disabled
                                    />
                                </div>


                            </div>
                        </div>
                        {showaccelerometers && (
                            <>
                                {/*<div className='w-11/12 ml-32 p-6 pt-20 flex'>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={accelerometerbtn}>Accelerometer</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={straingaugebtn}>Strain Gauge</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={accelerometerbtn}>Deflection Gauge</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={accelerometerbtn}>Camera</button>
                    </div>
                    <select
                        value={selectedSensorNumber}
                        onChange={(e) => setSelectedSensorNumber(e.target.value)}
                        className='ml-40 p-2 border border-black rounded-lg'>
                        <option value="">Select Sensor</option>
                        {sensorNumbers.map((sensorNum) => (
                            <option key={sensorNum} value={sensorNum}>{sensorNum}</option>
                        ))}
                    </select>*/}
                                <div className='w-auto mr-8 p-6 flex flex-wrap justify-center items-center bg-white'>
                                    {/* Accelerometer and Tiltmeter in one row */}
                                    <div style={{ display: 'flex', gap: '20px', marginBottom: '20px', width: '100%' }}>
                                        {/* Accelerometer */}
                                        <div style={{ display: 'flex', gap: '20px', marginBottom: '20px', width: '100%' }}>
                                            {/* Accelerometer Section */}
                                            <div style={{ border: '2px solid black', padding: '20px', borderRadius: '10px', height: '555px', width: '850px' }}>
                                                {/* Header with Title and Dropdowns */}
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                                    <h1 className='text-2xl'>Accelerometer</h1>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <h2 style={{ fontSize: '16px', fontWeight: 'bold' }}></h2>
                                                        <select onChange={(e) => handleDownload(e.target.value)} className="border border-black rounded-lg p-2 shadow-sm" style={{ width: '150px' }}>
                                                            <option value="">Download</option>
                                                            <option value="excel">Download Excel</option>
                                                            <option value="pdf">Download PDF</option>
                                                            <option value="word">Download Word</option>
                                                        </select>
                                                        <select onChange={(e) => handleDataSourceChange(e.target.value)} className="border border-black rounded-lg p-2 shadow-sm" style={{ width: '150px' }}>
                                                            <option value="api">Fetch Data from API</option>
                                                            <option value="csv">Upload CSV</option>
                                                        </select>
                                                        {dataSource === 'csv' && (
                                                            <input
                                                                type="file"
                                                                accept=".csv"
                                                                onChange={(e) => handleCSVUpload(e.target.files[0])}
                                                                className="border border-black rounded-lg p-2 shadow-sm"
                                                                style={{ width: '150px' }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Controls for Axis and Date Range */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
                                                    {/* Select Axis */}
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Select Axis: </label>
                                                        <select value={selectedAxis} onChange={(e) => setSelectedAxis(e.target.value)} className="yellow-dropdown" style={{ border: '2px solid black' }}>
                                                            <option value="all">All three Axis</option>
                                                            <option value="x">X-Axis</option>
                                                            <option value="y">Y-Axis</option>
                                                            <option value="z">Z-Axis</option>
                                                        </select>
                                                    </div>

                                                    {/* Select Start Date */}
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Select Start Date: </label>
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                            dateFormat="MM-dd-yy"
                                                            maxDate={new Date()}
                                                            minDate={new Date().setMonth(new Date().getMonth() - 3)}
                                                            className="border border-black rounded-lg p-2 shadow-sm"
                                                        />
                                                    </div>

                                                    {/* Select End Date */}
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Select End Date: </label>
                                                        <DatePicker
                                                            selected={endDate}
                                                            onChange={(date) => setEndDate(date)}
                                                            dateFormat="MM-dd-yy"
                                                            maxDate={new Date()}
                                                            minDate={startDate}
                                                            className="border border-black rounded-lg p-2 shadow-sm"
                                                        />
                                                    </div>
                                                </div>
                                                {/* Chart */}

                                                <div>


                                                    <div style={{ width: '100%', height: '400px', overflow: 'auto', }}>
                                                        <Line ref={chartRef} data={liveChartData} options={chartOptions} />
                                                    </div>
                                                </div>



                                            </div>

                                            {/* Download Data Section */}
                                        </div>


                                        {/* Tiltmeter */}
                                        <div style={{ border: '2px solid black', padding: '20px', borderRadius: '10px', width: '850px', height: '555px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                                                <h1 className='text-2xl'>Tiltmeter</h1>

                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <h2 style={{ fontSize: '16px', fontWeight: 'bold' }}>Download Data</h2>
                                                    <select onChange={(e) => handleDownloadTilt(e.target.value)} className="border border-black rounded-lg p-2 shadow-sm" style={{ width: '150px' }}>
                                                        <option value="">Select Format</option>
                                                        <option value="excel">Download Excel</option>
                                                        <option value="pdf">Download PDF</option>
                                                        <option value="word">Download Word</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '15px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Select Axis: </label>
                                                    <select
                                                        value={selectedTiltAxis}
                                                        onChange={(e) => setSelectedTiltAxis(e.target.value)}
                                                        className="yellow-dropdown"
                                                        style={{ border: '2px solid black' }}
                                                    >
                                                        <option value="all">All three Axis</option>
                                                        <option value="x">X-Axis</option>
                                                        <option value="y">Y-Axis</option>
                                                        <option value="z">Z-Axis</option>
                                                    </select>
                                                </div>

                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Select Start Date: </label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => setStartDate(date)}
                                                        dateFormat="MM-dd-yy"
                                                        maxDate={new Date()}
                                                        minDate={new Date().setMonth(new Date().getMonth() - 3)}
                                                        className="border border-black rounded-lg p-2 shadow-sm"
                                                    />
                                                </div>

                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Select End Date: </label>
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={(date) => setEndDate(date)}
                                                        dateFormat="MM-dd-yy"
                                                        maxDate={new Date()}
                                                        minDate={startDate}
                                                        className="border border-black rounded-lg p-2 shadow-sm"
                                                    />
                                                </div>
                                            </div>


                                            <div style={{ height: '400px', width: '800px' }}>
                                                <Line data={tiltGraphData} options={options} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Temperature and Pressure in one row */}
                                    <div style={{ display: 'flex', gap: '20px', marginBottom: '20px', width: '100%' }}>
                                        {/* Temperature Monitoring */}
                                        <div style={{ border: '2px solid black', padding: '30px', borderRadius: '10px', height: '555px', width: '850px' }}>
                                            {/* Title */}
                                            <h1 className='text-2xl'>Temperature</h1><br></br>

                                            {/* Controls for Start Date, End Date, and Download Option */}
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
                                                {/* Start Date */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Start Date: </label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => setStartDate(date)}
                                                        dateFormat="MM-dd-yy"
                                                        maxDate={new Date()}
                                                        minDate={new Date().setMonth(new Date().getMonth() - 3)}  // Limit date range to 3 months
                                                        className="border border-black rounded-lg p-2 shadow-sm"
                                                    />
                                                </div>

                                                {/* End Date */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <label style={{ fontWeight: 'bold', fontSize: '13px' }}>End Date: </label>
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={(date) => setEndDate(date)}
                                                        dateFormat="MM-dd-yy"
                                                        maxDate={new Date()}
                                                        minDate={startDate}  // Ensure end date is after start date
                                                        className="border border-black rounded-lg p-2 shadow-sm"
                                                    />
                                                </div>

                                                {/* Download Option */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <h2 style={{ fontSize: '16px', fontWeight: 'bold' }}>Download Data</h2>
                                                    <select onChange={(e) => handleDownloadTemperature(e.target.value)} className="border border-black rounded-lg p-2 shadow-sm" style={{ width: '150px' }}>
                                                        <option value="">Select Format</option>
                                                        <option value="excel">Download Excel</option>
                                                        <option value="pdf">Download PDF</option>
                                                        <option value="word">Download Word</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Temperature Chart */}
                                            {temperatureData.length > 0 ? (
                                                <div style={{ height: '400px', width: '800px' }}>
                                                    <Line data={temperatureChartData} options={temperatureChartOptions} />
                                                </div>
                                            ) : (
                                                <p>Loading data...</p>
                                            )}

                                        </div>


                                        {/* Pressure Monitoring */}
                                        <div style={{ border: '2px solid black', padding: '20px', borderRadius: '10px', width: '850px', height: '555px' }}>
                                            {/* Header with Download Option */}
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
                                                <h1 className='text-2xl'>Pressure</h1>

                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <h2 style={{ fontSize: '16px', fontWeight: 'bold' }}>Download Data</h2>
                                                    <select onChange={(e) => handleDownloadPressure(e.target.value)} className="border border-black rounded-lg p-2 shadow-sm" style={{ width: '150px' }}>
                                                        <option value="">Select Format</option>
                                                        <option value="excel">Download Excel</option>
                                                        <option value="pdf">Download PDF</option>
                                                        <option value="word">Download Word</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Axis Selection and Date Picker */}
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '15px' }}>
                                                {/* Axis Selector */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Select Axis: </label>
                                                    <select
                                                        value={selectedPressureAxis}
                                                        onChange={e => setSelectedPressureAxis(e.target.value)}
                                                        className="yellow-dropdown"
                                                        style={{ border: '2px solid black' }}
                                                    >
                                                        <option value="x">X-Axis</option>
                                                        <option value="y">Y-Axis</option>
                                                        <option value="z">Z-Axis</option>
                                                    </select>
                                                </div>

                                                {/* Start Date Picker */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Select Start Date: </label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={date => setStartDate(date)}
                                                        dateFormat="MM-dd-yy"
                                                        maxDate={new Date()}
                                                        minDate={new Date().setMonth(new Date().getMonth() - 3)}  // Limit to last 3 months
                                                        className="border border-black rounded-lg p-2 shadow-sm"
                                                    />
                                                </div>

                                                {/* End Date Picker */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Select End Date: </label>
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={date => setEndDate(date)}
                                                        dateFormat="MM-dd-yy"
                                                        maxDate={new Date()}
                                                        minDate={startDate}
                                                        className="border border-black rounded-lg p-2 shadow-sm"
                                                    />
                                                </div>
                                            </div>



                                            {/* Pressure Chart */}
                                            {pressureData.length > 0 ? (
                                                <div style={{ height: '400px', width: '800px' }}>
                                                    <Line data={pressureChartData} options={pressureChartOptions} />
                                                </div>
                                            ) : (
                                                <p>Loading data...</p>
                                            )}
                                        </div>

                                    </div>

                                    {/* Humidity and Battery Gauge in one row */}
                                    <div style={{ display: 'flex', gap: '20px', marginBottom: '20px', width: '100%' }}>
                                        {/* Humidity Monitoring */}
                                        <div style={{ border: '2px solid black', padding: '30px', borderRadius: '10px', height: '555px', width: '850px' }}>
                                            {/* Title */}
                                            <h1 className='text-2xl'>Humidity</h1>

                                            {/* Controls for Start Date, End Date, and Download Option */}
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
                                                {/* Start Date */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Start Date: </label>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => setStartDate(date)}
                                                        dateFormat="MM-dd-yy"
                                                        maxDate={new Date()}
                                                        minDate={new Date().setMonth(new Date().getMonth() - 3)}  // Limit date range to 3 months
                                                        className="border border-black rounded-lg p-2 shadow-sm"
                                                    />
                                                </div>

                                                {/* End Date */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <label style={{ fontWeight: 'bold', fontSize: '13px' }}>End Date: </label>
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={(date) => setEndDate(date)}
                                                        dateFormat="MM-dd-yy"
                                                        maxDate={new Date()}
                                                        minDate={startDate}  // Ensure end date is after start date
                                                        className="border border-black rounded-lg p-2 shadow-sm"
                                                    />
                                                </div>

                                                {/* Download Option */}
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <h2 style={{ fontSize: '16px', fontWeight: 'bold' }}>Download Data</h2>
                                                    <select onChange={(e) => handleDownload(e.target.value)} className="border border-black rounded-lg p-2 shadow-sm" style={{ width: '150px' }}>
                                                        <option value="">Select Format</option>
                                                        <option value="excel">Download Excel</option>
                                                        <option value="pdf">Download PDF</option>
                                                        <option value="word">Download Word</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Humidity Chart */}
                                            {humidityData.length > 0 ? (
                                                <div style={{ height: '500px', width: '800px' }}>
                                                    <Line data={humidityGraphData} options={humidityOptions} />
                                                </div>
                                            ) : (
                                                <p>Loading data...</p>
                                            )}
                                        </div>


                                        {/* Battery Gauge */}
                                        <BatteryGauge />

                                    </div>
                                </div>



                            </>
                        )}

                        <div className='bg-indigo-800 w-2/5 text-center py-6 overflow-hidden shadow-xl rounded-xl mt-20 text-white'>
                            {typeof Weather.main != "undefined" ? (
                                <div className='flex justify-center'>
                                    <div className='w-1/4'>
                                        <div className='flex justify-start'>
                                            {Weather.weather[0].main === "Clouds" && (
                                                <img className='w-24' src={cloud_icon} alt="Cloud Icon" />
                                            )}
                                            {Weather.weather[0].main === "Smoke" && (
                                                <img className='w-24' src={mist_icon} alt="Cloud Icon" />
                                            )}
                                            {Weather.weather[0].main === "Haze" && (
                                                <img className='w-24' src={mist_icon} alt="Cloud Icon" />
                                            )}
                                            {Weather.weather[0].main === "Clear" && (
                                                <img className='w-24' src={clear_icon} alt="Clear Icon" />
                                            )}
                                            {Weather.weather[0].main === "Rain" && (
                                                <img className='w-24' src={rain_icon} alt="Clear Icon" />
                                            )}
                                            {Weather.weather[0].main === "Mist" && (
                                                <img className='w-24' src={mist_icon} alt="Clear Icon" />
                                            )}
                                            {Weather.weather[0].main === "Snow" && (
                                                <img className='w-24' src={snow_icon} alt="Clear Icon" />
                                            )}
                                            {Weather.weather[0].main === "Thunderstorm" && (
                                                <img className='w-24' src={thunderstorm_icon} alt="Clear Icon" />
                                            )}
                                            {Weather.weather[0].main === "Drizzle" && (
                                                <img className='w-24' src={drizzle_icon} alt="Clear Icon" />
                                            )}
                                        </div>
                                        <div>
                                            <p className='text-3xl mt-4 text-left'>{Weather.weather[0].main}</p>
                                        </div>
                                    </div>

                                    <div className='w-1/3 text-left'>
                                        <p className='text-4xl font-semibold'>{Weather.name}, {Weather.sys.country}</p><br />
                                        <p className='text-4xl font-semibold'>{Weather.main.temp}°C</p>
                                        <p className='text-xl'>({Weather.weather[0].description})</p>
                                    </div>

                                    <div className='w-1/3'>
                                        <div className='flex'>
                                            <WiHumidity size={25} />
                                            <p className='ml-2 text-sm'>Air Humidity: {Weather.main.humidity}%</p>
                                        </div>
                                        <div className=' mt-2 flex'>
                                            <PiWind size={25} />
                                            <p className='ml-2 text-sm'>Wind Speed: {Weather.wind.speed} km/h</p>
                                        </div>
                                        <div className='mt-2 flex'>
                                            <GiSpeedometer size={20} />
                                            <p className='ml-2 text-sm'>Air Pressure: {Weather.main.pressure} mBar</p>
                                        </div>
                                    </div>
                                </div>
                            ) :
                                (
                                    <div>No weather report could be found for {userData.city}. <br />Edit the city name to check if the area's weather gets shown. <br /> Source: https://openweathermap.org/</div>
                                )}
                        </div>
                        <div className='viewDiv' ref={mapRef}>

                        </div>
                    </div>
                </>
            )}




            {showstraingauges && (
                <>
                    <div className='w-11/12 ml-32 p-6 pt-20 flex'>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={accelerometerbtn}>Accelerometer</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={straingaugebtn}>Strain Gauge</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={accelerometerbtn}>Deflection Gauge</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white' onClick={accelerometerbtn}>Camera</button>
                    </div>
                    <div className='w-11/12 ml-24 p-6 pt-12 flex bg-white'>
                        <div>
                            <div>
                                <h2>Strain Gauge</h2>
                                <div>
                                    <label>Select Axis: </label>
                                    <select value={selectedAxis1} onChange={e => setSelectedAxis1(e.target.value)} className="yellow-dropdown">
                                        <option value="all">All three Axis</option>
                                        <option value="x">X-Axis</option>
                                        <option value="y">Y-Axis</option>
                                        <option value="z">Z-Axis</option>
                                    </select>
                                </div>
                                {accelData1.length > 0 ? (
                                    <div style={{ height: '400px', width: '600px' }}>
                                        <Line data={data1} />
                                    </div>
                                ) : (
                                    <p>Loading data...</p>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}


            {showSensorDashboard && (
                <>
                    <h1 className='w-11/12 ml-24 text-center p-6 pt-24 text-pink-600 text-4xl font-semibold'>Sensor 1</h1><hr />
                    <div className='w-11/12 ml-24 p-6 pt-6 flex'>
                        <div className="bg-gray-100 w-1/2 mx-8 shadow-xl">
                            <br />
                            <h1 className='text-center font-bold'>Sensor 1 Temperature Monitoring</h1><br />
                            {chartData2.labels && chartData2.datasets && chartData2.labels.length > 0 && chartData2.datasets.length > 0 ? (
                                <Line data={chartData2} />
                            ) : (
                                <h1>Loading...</h1>
                            )}
                        </div>

                        <div className="bg-gray-100 w-1/2 shadow-xl">
                            <br />
                            <h1 className='text-center font-bold'>Sensor 1 Frequency Monitoring</h1><br />
                            {chartData3.labels && chartData3.datasets && chartData3.labels.length > 0 && chartData3.datasets.length > 0 ? (
                                <Line data={chartData3} />
                            ) : (
                                <h1>Loading...</h1>
                            )}
                        </div>
                    </div>
                    <div className='w-11/12 ml-24 p-6 flex pt-14'>

                        <div className="bg-gray-100 w-1/2 mx-8 shadow-xl">
                            <br />
                            <h1 className='text-center font-bold'>Sensor 1 Eng Monitoring</h1><br />
                            {chartData4.labels && chartData4.datasets && chartData4.labels.length > 0 && chartData4.datasets.length > 0 ? (
                                <Line data={chartData4} />
                            ) : (
                                <h1>Loading...</h1>
                            )}
                        </div>

                        <div className='grid w-1/4'>
                            <div className="bg-gray-100 w-4/5 mx-6 mb-6 shadow-2xl rounded-xl"><br />
                                <h2 className="text-lg font-semibold text-center text-gray-600">Sensor 1 avg Temperature</h2><br />
                                <h1 className='text-center font-bold text-6xl text-gray-800'>{averageSensor1Temp} </h1><br />
                            </div>

                            <div className="bg-gray-100 w-4/5 mx-6 shadow-2xl rounded-xl"><br />
                                <h2 className="text-lg font-semibold text-center text-gray-600">Sensor 1 avg Eng</h2><br />
                                <h1 className='text-center font-bold text-6xl text-gray-800'>{averageSensor1Eng} </h1><br />
                            </div>

                        </div>
                        <div className='grid w-1/4'>
                            <div className="bg-gray-100 w-4/5 mx-6 shadow-2xl mb-6 rounded-xl"><br />
                                <h2 className="text-lg font-semibold text-center text-gray-600">Sensor 1 avg Frequency</h2><br />
                                <h1 className='text-center font-bold text-6xl text-gray-800'>{averageSensor1Freq}</h1><br />
                            </div>

                            <div className="bg-pink-600 mx-6 w-4/5 text-white pb-10 shadow-2xl cursor-pointer rounded-xl hover:bg-pink-800"><br />
                                <h2 className="text-lg font-semibold text-center">Avg Logger Temp</h2><br />
                                <FaArrowCircleRight className='' style={{ width: '100%', alignItems: 'center' }} size={40} />
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <hr />
                    {/* Sensor 2 */}
                    <h1 className='w-11/12 ml-24 text-center p-6 text-pink-600 text-4xl font-semibold'>Sensor 2</h1><hr />
                    <div className='w-11/12 ml-24 p-6 pt-6 flex'>
                        <div className="bg-gray-100 w-1/2 mx-8 shadow-xl">
                            <br />
                            <h1 className='text-center font-bold'>Sensor 2 Temperature Monitoring</h1><br />
                            {chartData5.labels && chartData5.datasets && chartData5.labels.length > 0 && chartData5.datasets.length > 0 ? (
                                <Line data={chartData5} />
                            ) : (
                                <h1>Loading...</h1>
                            )}
                        </div>

                        <div className="bg-gray-100 w-1/2 shadow-xl">
                            <br />
                            <h1 className='text-center font-bold'>Sensor 2 Frequency Monitoring</h1><br />
                            {chartData6.labels && chartData6.datasets && chartData6.labels.length > 0 && chartData6.datasets.length > 0 ? (
                                <Line data={chartData6} />
                            ) : (
                                <h1>Loading...</h1>
                            )}
                        </div>
                    </div>
                    <div className='w-11/12 ml-24 p-6 flex pt-14'>

                        <div className="bg-gray-100 w-1/2 mx-8 shadow-xl">
                            <br />
                            <h1 className='text-center font-bold'>Sensor 2 Eng Monitoring</h1><br />
                            {chartData7.labels && chartData7.datasets && chartData7.labels.length > 0 && chartData7.datasets.length > 0 ? (
                                <Line data={chartData7} />
                            ) : (
                                <h1>Loading...</h1>
                            )}
                        </div>

                        <div className='grid w-1/4'>
                            <div className="bg-gray-100 w-4/5 mx-6 mb-6 shadow-2xl rounded-xl"><br />
                                <h2 className="text-lg font-semibold text-center text-gray-600">Sensor 2 avg Temperature</h2><br />
                                <h1 className='text-center font-bold text-6xl text-gray-800'>{averageSensor2Temp} </h1><br />
                            </div>

                            <div className="bg-gray-100 w-4/5 mx-6 shadow-2xl rounded-xl"><br />
                                <h2 className="text-lg font-semibold text-center text-gray-600">Sensor 2 avg Eng</h2><br />
                                <h1 className='text-center font-bold text-6xl text-gray-800'>{averageSensor2Eng} </h1><br />
                            </div>

                        </div>
                        <div className='grid w-1/4'>

                            <div className="bg-gray-100 w-4/5 mx-6 shadow-2xl mb-6 rounded-xl"><br />
                                <h2 className="text-lg font-semibold text-center text-gray-600">Sensor 2 avg Frequency</h2><br />
                                <h1 className='text-center font-bold text-6xl text-gray-800'>{averageSensor2Freq}</h1><br />
                            </div>

                            <div className="bg-pink-600 mx-6 w-4/5 text-white pb-10 shadow-2xl cursor-pointer rounded-xl hover:bg-pink-800"><br />
                                <h2 className="text-lg font-semibold text-center">Avg Logger Temp</h2><br />
                                <FaArrowCircleRight className='' style={{ width: '100%', alignItems: 'center' }} size={40} />
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <hr />
                    <h1 className='w-11/12 ml-24 text-center p-6 text-pink-600 text-4xl font-semibold'>General Details</h1><hr />
                    <div className='w-11/12 ml-24 p-6 flex bg-white'>
                        <div className="bg-gray-100 w-1/2 mx-8 shadow-xl">
                            <h1 className='text-center font-bold'>Battery Voltage Monitoring</h1><br />
                            {chartData.labels && chartData.datasets && chartData.labels.length > 0 && chartData.datasets.length > 0 ? (
                                <Line data={chartData} />
                            ) : (
                                <h1>Loading...</h1>
                            )}
                        </div>

                        <div className="bg-gray-100 w-1/2 shadow-xl">
                            <h1 className='text-center font-bold'>Logger Temperature Monitoring</h1><br />
                            {chartData1.labels && chartData1.datasets && chartData1.labels.length > 0 && chartData1.datasets.length > 0 ? (
                                <Line data={chartData1} />
                            ) : (
                                <h1>Loading...</h1>
                            )}
                        </div>
                    </div>
                    <div className='w-11/12 ml-24 p-6 flex'>
                        <div className="bg-gray-100 w-1/6 mx-6 shadow-2xl rounded-xl"><br />
                            <h2 className="text-lg font-semibold text-center text-gray-600">Avg Battery Voltage</h2><br />
                            <h1 className='text-center font-bold text-6xl text-gray-800'>{averageBatteryVoltage} </h1><br />
                        </div>
                        <div className="bg-gray-100 w-1/6 mx-6 shadow-2xl rounded-xl"><br />
                            <h2 className="text-lg font-semibold text-center text-gray-600">Avg Logger Temp</h2><br />
                            <h1 className='text-center font-bold text-6xl text-gray-800'>{averageLoggerTemp}</h1><br />
                        </div>
                        <div className="bg-pink-600 w-2/3 shadow-2xl mx-6 rounded-xl text-white p-3 pl-6">
                            <h1 className='text-2xl'>General Dashboard</h1> <hr /><br />
                            <p className='text-sm'>The dashboard displays real-time data on battery voltage and logger temperature, allowing
                                users to monitor system health and performance. With intuitive visualizations,
                                it provides insights into power supply stability and environmental conditions, facilitating
                                informed decision-making and proactive maintenance.</p>
                        </div>
                    </div>
                </>
            )}


            {showModify && (
                <>
                    <div className='w-11/12 z-30 fixed bg-white ml-24 p-6 pt-20 flex mx-8'>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white text-lg' onClick={showBridgeInfo}>Bridge Information</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white text-lg' onClick={showSensorInfo}>Sensor Information</button>
                        <button className='w-1/3 p-2 border border-gray-300 rounded-lg mx-2 overflow-hidden shadow-lg hover:bg-indigo-800 hover:text-white text-lg' onClick={showUserInfo}>User Information</button>
                    </div>
                </>
            )}

            {showBridgeDetails && (
                <>
                    <div className='w-11/12 ml-24 p-6 pt-40 bg-white'>
                        <form>
                            <h1 className='text-center text-3xl w-full font-semibold pb-12'>&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash; Bridge Information &ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;</h1>
                            <div className="flex w-full pl-16 py-6 justify-center bg-gray-100">

                                <div className='w-1/3 px-2 justify-center'>
                                    <div className="mb-6">
                                        <label htmlFor="country">Select Country:</label><br></br>
                                        <select id="country" value={userData.country} onChange={handleCountryChange} className="p-2 pl-4 w-3/4 overflow-hidden shadow-md outline-0 rounded-lg">
                                            <option value="USA">USA</option>
                                            <option value="India">India</option>
                                        </select>
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="division" className="block text-gray-700">Division:</label>
                                        <input type="text" id="division" placeholder='Enter Division' name="division" value={userData.division} onChange={(e) => setUserData(prevData => ({ ...prevData, division: e.target.value }))} className="p-2 w-3/4 overflow-hidden shadow-md outline-0 rounded-lg" />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="division" className="block text-gray-700">Bridge Name:</label>
                                        <input type="text" id="name" placeholder='Enter Name' name="name" value={userData.bridgeName} onChange={(e) => setUserData(prevData => ({ ...prevData, bridgeName: e.target.value }))} className="p-2 w-3/4 overflow-hidden shadow-md outline-0 rounded-lg" />
                                    </div>
                                </div>

                                <div className="w-1/3 px-2 justify-center">
                                    <div className="mb-6">
                                        <label htmlFor="state" className="block text-gray-700">Select State:</label>
                                        <select id="state" value={userData.state} onChange={handleStateChange} className="p-2 pl-4 w-3/4 overflow-hidden shadow-md outline-0 rounded-lg">
                                            <option value="">{userData.state}</option>
                                            {statesList.map((stateName) => (
                                                <option key={stateName} value={stateName}>{stateName}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor='bridgeName' className="block text-gray-700">Zip Code:</label>
                                        <input type="text" id="location" placeholder='Enter Zip Code' name="location" value={userData.zipCode} onChange={(e) => setUserData(prevData => ({ ...prevData, location: e.target.value }))} className="p-2 w-3/4 overflow-hidden shadow-md outline-0 rounded-lg" />
                                    </div>

                                </div>

                                <div className='w-1/3 px-2 justify-center'>
                                    <div className='mb-6'>
                                        <label htmlFor="city" className="block text-gray-700">City:</label>
                                        <input type="text" id="city" placeholder='Enter City / Area' name="city" value={userData.city} onChange={(e) => setUserData(prevData => ({ ...prevData, city: e.target.value }))} className="p-2 pl-4 w-3/4 overflow-hidden shadow-md outline-0 rounded-lg" />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="coordinates" className="block text-gray-700">Bridge Coordinates:</label>
                                        <input type="text" id="coordinates" placeholder='Enter Coordinates' name="coordinates" value={userData.coordinates} onChange={(e) => setUserData(prevData => ({ ...prevData, coordinates: e.target.value }))} className="p-2 w-3/4 overflow-hidden shadow-md outline-0 rounded-lg" />
                                    </div>

                                </div>
                            </div>
                        </form>
                        <div className='text-center'>
                            <button className='p-2 bg-pink-600 w-full text-white px- rounded-sm hover:bg-pink-900' onClick={updateData}>Save</button>
                        </div>
                    </div>
                </>
            )}

            {showSensorDetails && (
                <>
                    <div className='w-11/12 ml-24 p-6 pt-40 bg-white'>
                        {sensorDataList.length === 0 ? (
                            <div className="text-center text-gray-700">No sensors found</div>
                        ) : (
                            sensorDataList.map((sensorData, index) => (
                                <div key={index} className='w-11/12 ml-24 p-6 pt-24 bg-white'>
                                    <h1 className='text-center text-3xl w-full font-semibold pb-12'>Sensor Information - {sensorData.id}</h1>
                                    <div className="mb-6 px-96 w-full">
                                        <label htmlFor="sensortype" className="block text-gray-700">Sensor Type:</label>
                                        <select id="sensortype" name="sensortype" value={sensorData.sensortype} onChange={(e) => setUserData(prevData => ({ ...prevData, sensortype: e.target.value }))} className="border border-gray-300 p-2 w-full rounded">
                                            <option value="Accelerometer">Accelerometer</option>
                                            <option value="Strain Gauge">Strain Gauge</option>
                                            <option value="Deflection Gauge">Deflection Gauge</option>
                                            <option value="Camera">Camera</option>
                                        </select>
                                    </div>
                                    <h1 className='font-semibold text-lg'>Sensor Location</h1>
                                    <div className="mb-2 w-full px-5">
                                        <label htmlFor={`spanno-${index}`} className="block text-gray-700">Span Number:</label>
                                        <select id={`spanno-${index}`} name={`spanno-${index}`} value={sensorData.spanno} onChange={(e) => setsensorData(prevData => ({ ...prevData, spanno: e.target.value }))} className="border border-gray-300 p-1 w-full rounded">
                                            {Array.from({ length: parseInt(userData.nobridgespan) }, (_, i) => (
                                                <option key={`span-${i + 1}`} value={i + 1}>{i + 1}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-2 w-full px-5">
                                        <label htmlFor={`girderno-${index}`} className="block text-gray-700">Girder Number:</label>
                                        <select id={`girderno-${index}`} name={`girderno-${index}`} value={sensorData.girderno} onChange={(e) => setsensorData(prevData => ({ ...prevData, girderno: e.target.value }))} className="border border-gray-300 p-1 w-full rounded">
                                            {Array.from({ length: parseInt(userData.noofgirders) }, (_, i) => (
                                                <option key={`girder-${i + 1}`} value={i + 1}>{i + 1}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-2 w-full px-5">
                                        <label htmlFor={`manualLocation-${index}`} className="block text-gray-700">Manual Location:</label>
                                        <input id={`manualLocation-${index}`} name={`manualLocation-${index}`} value={sensorData.manualLocation} onChange={(e) =>
                                            setsensorData((prevData) => ({
                                                ...prevData,
                                                manualLocation: e.target.value,
                                            }))
                                        } placeholder="Ex: Top-Right, Bottom Left, etc" className="border  border-gray-300 p-1 w-full rounded overflow-hidden shadow-md outline-none" />
                                    </div>

                                </div>
                            ))
                        )}
                        <div className='text-center'>
                            <button className='mt-12 p-2 bg-pink-600 text-white px-6 rounded-sm hover:bg-pink-900' onClick={() => updateSensorData(sensorData.id, sensorData)}>Save</button>
                        </div>
                    </div>
                </>
            )}

            {showUser && (
                <>
                    <div className='w-11/12 ml-24 p-6 pt-40 bg-white'>
                        <h1 className='text-center text-3xl w-full font-semibold pb-12'>&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash; User Information &ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;&ndash;</h1>
                        <div className='text-left text-gray-800'>
                            <h3 className='font-semibold pb-6'>Added Admin(s):</h3>
                            <div className='flex'>
                                <input id='adminName' value={userData.adminName || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, adminName: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 pl-3 p-1 outline-none" type="text" placeholder='Name (Admin 1)' />
                                <input id='adminEmail' value={userData.adminEmail || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, adminEmail: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 pl-3 p-1 outline-none" type="email" placeholder='email' />
                                <PhoneInput country={'us'} value={String(userData.adminPhone || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, adminPhone: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-0 rounded overflow-hidden outline-none shadow-md ml-8 p-2 w-3/4', }} />
                                <button className='w-1/6' onClick={removeAdmin1}><FaTrash size={20} /></button>
                            </div>
                            <form action="submit">
                                <div className='mt-5 flex'>
                                    <input id='adminName2' value={userData.adminName2 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, adminName2: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 pl-3 outline-none p-1" type="text" placeholder='Name (Admin 2)' />
                                    <input id='adminEmail2' value={userData.adminEmail2 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, adminEmail2: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 outline-none pl-3 p-1" type="email" placeholder='email' />
                                    <PhoneInput country={'us'} value={String(userData.adminPhone2 || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, adminPhone2: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-0 outline-none rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                    <button className='w-1/6' onClick={removeAdmin2}><FaTrash size={20} /></button>
                                </div>
                                <div className='mt-5 flex'>
                                    <input id='adminName3' value={userData.adminName3 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, adminName3: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full outline-none mx-4 pl-3 p-1" type="text" placeholder='Name (Admin 3)' />
                                    <input id='adminEmail3' value={userData.adminEmail3 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, adminEmail3: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full outline-none mx-4 pl-3 p-1" type="email" placeholder='email' />
                                    <PhoneInput country={'us'} value={String(userData.adminPhone3 || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, adminPhone3: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-none outline-0 rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                    <button className='w-1/6' onClick={removeAdmin3}><FaTrash size={20} /></button>
                                </div>
                            </form>

                            <br /><br /><br />
                            <h3 className='font-semibold pb-6 mx-4'>Added Manager(s):</h3>
                            <div className='flex'>
                                <input id='managerName' value={userData.managerName || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerName: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md outline-none w-full mx-4 pl-3 p-1" type="text" placeholder='Name (Manager 1)' />
                                <input id='managerEmail' value={userData.managerEmail || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerEmail: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md outline-none w-full mx-4 pl-3 p-1" type="email" placeholder='email' />
                                <PhoneInput country={'us'} value={String(userData.managerPhone || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, managerPhone: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-none outline-0 rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                <button className='w-1/6' onClick={removeManager1}><FaTrash size={20} /></button>
                            </div>

                            <form action="submit">
                                <div className='mt-5 flex'>
                                    <input id='managerName2' value={userData.managerName2 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerName2: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md outline-none w-full mx-4 pl-3 p-1" type="text" placeholder='Name (Manager 2)' />
                                    <input id='managerEmail2' value={userData.managerEmail2 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerEmail2: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md outline-none w-full mx-4 pl-3 p-1" type="email" placeholder='email' />
                                    <PhoneInput country={'us'} value={String(userData.managerPhone2 || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, managerPhone2: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-none outline-0 rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                    <button className='w-1/6' onClick={removeManager2}><FaTrash size={20} /></button>
                                </div>


                                <div className='mt-5 flex'>
                                    <input id='managerName3' value={userData.managerName3 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerName3: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md outline-none w-full mx-4 pl-3 p-1" type="text" placeholder='Name (Manager 3)' />
                                    <input id='managerEmail3' value={userData.managerEmail3 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerEmail3: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md outline-none w-full mx-4 pl-3 p-1" type="email" placeholder='email' />
                                    <PhoneInput country={'us'} value={String(userData.managerPhone3 || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, managerPhone3: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-none outline-0 rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                    <button className='w-1/6' onClick={removeManager3}><FaTrash size={20} /></button>
                                </div>

                                <div className='mt-5 flex'>
                                    <input id='managerName4' value={userData.managerName4 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerName4: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full outline-none mx-4 pl-3 p-1" type="text" placeholder='Name (Manager 4)' />
                                    <input id='managerEmail4' value={userData.managerEmail4 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerEmail4: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full outline-none mx-4 pl-3 p-1" type="email" placeholder='email' />
                                    <PhoneInput country={'us'} value={String(userData.managerPhone4 || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, managerPhone4: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-0 outline-none rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                    <button className='w-1/6' onClick={removeManager4}><FaTrash size={20} /></button>
                                </div>

                                <div className='mt-5 flex'>
                                    <input id='managerName5' value={userData.managerName5 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerName5: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full outline-none mx-4 pl-3 p-1" type="text" placeholder='Name (Manager 5)' />
                                    <input id='managerEmail5' value={userData.managerEmail5 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerEmail5: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full outline-none mx-4 pl-3 p-1" type="email" placeholder='email' />
                                    <PhoneInput country={'us'} value={String(userData.managerPhone5 || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, managerPhone5: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-0 outline-none rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                    <button className='w-1/6' onClick={removeManager5}><FaTrash size={20} /></button>
                                </div>

                                <div className='mt-5 flex'>
                                    <input id='managerName6' value={userData.managerName6 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerName6: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 outline-none pl-3 p-1" type="text" placeholder='Name (Manager 6)' />
                                    <input id='managerEmail6' value={userData.managerEmail6 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, managerEmail6: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 outline-none pl-3 p-1" type="email" placeholder='email' />
                                    <PhoneInput country={'us'} value={String(userData.managerPhone6 || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, managerPhone6: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-0 outline-none rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                    <button className='w-1/6' onClick={removeManager6}><FaTrash size={20} /></button>
                                </div>
                            </form>

                            <br /><br /><br />
                            <h3 className='font-semibold pb-6'>Added Owner(s):</h3>
                            <div className='flex'>
                                <input id='ownerName' value={userData.ownerName || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, ownerName: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 pl-3 outline-none p-1" type="text" placeholder='Name (Owner 1)' />
                                <input id='ownerEmail' value={userData.ownerEmail || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, ownerEmail: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 pl-3 outline-none p-1" type="email" placeholder='email' />
                                <PhoneInput country={'us'} value={String(userData.ownerPhone || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, ownerPhone: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-0 outline-none rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                <button className='w-1/6' onClick={removeOwner1}><FaTrash size={20} /></button>
                            </div>

                            <form action="submit">
                                <div className='mt-5 flex'>
                                    <input id='ownerName2' value={userData.ownerName2 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, ownerName2: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 outline-none pl-3 p-1" type="text" placeholder='Name (Owner 2)' />
                                    <input id='ownerEmail2' value={userData.ownerEmail2 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, ownerEmail2: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 outline-none pl-3 p-1" type="email" placeholder='email' />
                                    <PhoneInput country={'us'} value={String(userData.ownerPhone2 || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, ownerPhone2: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-none rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                    <button className='w-1/6' onClick={removeOwner2}><FaTrash size={20} /></button>
                                </div>

                                <div className='mt-5 flex'>
                                    <input id='ownerName3' value={userData.ownerName3 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, ownerName3: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full mx-4 outline-none pl-3 p-1" type="text" placeholder='Name (Owner 3)' />
                                    <input id='ownerEmail3' value={userData.ownerEmail3 || ''} onChange={(e) => setUserData(prevData => ({ ...prevData, ownerEmail3: e.target.value }))} className="border border-gray-300 rounded overflow-hidden shadow-md w-full outline-none mx-4 pl-3 p-1" type="email" placeholder='email' />
                                    <PhoneInput country={'us'} value={String(userData.ownerPhone3 || '')} onChange={(value) => setUserData(prevData => ({ ...prevData, ownerPhone3: value }))} inputProps={{ required: true, className: 'relative border border-gray-300 outline-none rounded overflow-hidden shadow-md ml-8 p-2 w-3/4', }} />
                                    <button className='w-1/6' onClick={removeOwner3}><FaTrash size={20} /></button>
                                </div>
                            </form>
                        </div>
                        <div className='text-center mb-32'>
                            <button className='mt-12 p-2 bg-pink-600 text-white px-6 rounded-sm hover:bg-pink-900' onClick={updateData}>Save</button>
                        </div>
                    </div>
                </>
            )}
        </>

    )
};




export default Superuserhome;