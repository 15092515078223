import './App.css';
import Login from './components/Pages/Login'

function App() {
  return (
    <div>
      <Login/>
    </div>
  );
}

export default App;
